import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, createVNode(32, "g", null, [createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "0",
    "y": "0",
    "width": "85",
    "height": "120"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "95",
    "y": "0",
    "width": "85",
    "height": "120"
  })], 4, {
    "id": "icon_gallery_two",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  }), 2, {
    "width": "180px",
    "height": "120px",
    "viewBox": "0 0 180 120"
  });
}