import { Utility } from 'component-registry'
import classnames from 'classnames'
import {
  CardButton,
  LinkButton,
  MarkupButton,
  SectionButton,
  SectionSelect
} from 'influence-ux-mobiledoc-editor'
import { ButtonBar } from 'influence-ux-components'
import { ImageButton } from 'app-card-MediaCard'
import { IMobiledocWidgetToolbar } from 'app-field-MobiledocField'

import listBullet_svg from 'app-field-MobiledocField/icons/list_bullet.svg'
import listNumber_svg from 'app-field-MobiledocField/icons/list_number.svg'
import link_svg from 'app-field-MobiledocField/icons/link.svg'
import blockQuote_svg from 'app-field-MobiledocField/icons/block_quote.svg'
import italic_svg from 'app-field-MobiledocField/icons/italic.svg'
import bold_svg from 'app-field-MobiledocField/icons/bold.svg'

function Icon({className, ...props}) {
  return <img className={classnames("icon", className)} {...props} />
}

const Toolbar = ({className, ...props}) =>
  <ButtonBar className={className}>
    <SectionSelect className="ParagraphStyle" tags={[
      ["Header 1", "h1"],
      ["Header 2", "h2"],
      ["Header 3", "h3"],
      ["Paragraph", "p"]
    ]} title="Text Style" />
    <MarkupButton tag='strong'><Icon src={bold_svg} /></MarkupButton>
    <MarkupButton tag='em'><Icon src={italic_svg} /></MarkupButton>
    <LinkButton><Icon src={link_svg} /></LinkButton>
    <SectionButton tag='blockquote'><Icon src={blockQuote_svg} /></SectionButton>
    <SectionButton tag='ul'><Icon src={listBullet_svg} /></SectionButton>
    <SectionButton tag='ol'><Icon src={listNumber_svg} /></SectionButton>
    <CardButton utilName="MediaCard" {...props} />
  </ButtonBar>

new Utility({
  implements: IMobiledocWidgetToolbar,
  Component: Toolbar
})
