'use strict';
/*

    To use this input widget adapter you need to register it with your
    adapter registry.

*/

import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import { safeGet } from 'safe-utils';
import getWidgetAdapters from '../getWidgetAdapters';

function renderRows(_ref) {
  var schema = _ref.schema,
      value = _ref.value,
      selectFields = _ref.selectFields,
      omitFields = _ref.omitFields,
      lang = _ref.lang,
      namespace = _ref.namespace,
      inputName = _ref.inputName,
      validationErrors = _ref.validationErrors,
      isMounted = _ref.isMounted,
      customWidgets = _ref.customWidgets,
      onInput = _ref.onInput,
      onChange = _ref.onChange;
  var renderFields = Object.keys(schema._fields);
  var dottedNamespace = Array.isArray(namespace) ? namespace.join('.') : '';

  if (Array.isArray(selectFields) && selectFields.length > 0) {
    renderFields = renderFields.filter(function (key) {
      var dottedName = dottedNamespace ? "".concat(dottedNamespace, ".").concat(key) : key;
      var tmp = selectFields.filter(function (sel) {
        return sel === dottedName || sel.startsWith(dottedName + '.');
      });
      return tmp.length > 0;
    }); // Remove root level props so they aren't counted when we pass on to next level
    // so we can select all if none are passed

    selectFields = selectFields.filter(function (key) {
      return key.indexOf('.') >= 0;
    });
  }

  if (Array.isArray(omitFields)) {
    renderFields = renderFields.filter(function (key) {
      var dottedName = dottedNamespace ? "".concat(dottedNamespace, ".").concat(key) : key;
      return omitFields.indexOf(dottedName) < 0;
    });
  }

  var widgets = renderFields.map(function (propName) {
    // Call the validationConstraint methods to figure out if the field should be validated
    var shouldValidate = schema._validationConstraints.reduce(function (prev, curr) {
      return prev && curr(value, propName);
    }, true);

    if (!shouldValidate) {
      // Don't render fields that shouldn't be validated
      return;
    }

    var field = schema._fields[propName];
    var validationError = safeGet(function () {
      return validationErrors.fieldErrors[propName];
    }); // Support readOnly
    // Support validation constraints

    var myNamespace = namespace.concat([propName]); // .concat returns a new array

    var dotName = myNamespace.join('.'); // Unpack the invariant errors so they can be found by field key

    var tmpInvariantErrors = safeGet(function () {
      return validationErrors && validationErrors.invariantErrors && validationErrors.invariantErrors.filter(function (invErr) {
        // Pattern match field name of error with current namespace to see if it is a match
        return invErr.fields.reduce(function (prev, curr) {
          return prev || curr.indexOf(dotName) === 0;
        }, false);
      });
    });

    if (Array.isArray(tmpInvariantErrors) && tmpInvariantErrors.length > 0) {
      if (!validationError) validationError = {};
      validationError.invariantErrors = tmpInvariantErrors;
    }

    var _getWidgetAdapters = getWidgetAdapters(field, myNamespace.join('.'), customWidgets),
        InputFieldAdapter = _getWidgetAdapters.InputFieldAdapter,
        RowAdapter = _getWidgetAdapters.RowAdapter;

    var Row = RowAdapter.Component;
    var InputField = InputFieldAdapter.Component;
    var newInputName = inputName && propName ? inputName + '[' + propName + ']' : inputName || propName;
    var doesNotRenderLabel = RowAdapter.doesNotRenderLabel();
    var sharedProps = {
      namespace: myNamespace,
      propName: propName,
      value: value && value[propName],
      options: {
        parentValue: value,
        lang: lang
      },
      validationError: validationError,
      formIsMounted: isMounted,
      doesNotRenderLabel: doesNotRenderLabel,
      id: dotName,
      // Callbacks
      onChange: onChange,
      onInput: onInput
    }; // TODO: Key should be namespace parent.propName?

    return normalizeProps(createComponentVNode(2, Row, _objectSpread(_objectSpread({
      "adapter": RowAdapter
    }, sharedProps), {}, {
      children: normalizeProps(createComponentVNode(2, InputField, _objectSpread({
        "adapter": InputFieldAdapter,
        "inputName": newInputName,
        "customWidgets": customWidgets,
        "selectFields": selectFields,
        "omitFields": omitFields
      }, sharedProps)))
    }), myNamespace.join('.')));
  });
  return widgets;
}

export { renderRows };