import { globalRegistry, createObjectPrototype, Utility } from 'component-registry';
import { IMedia } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { Permissions } from 'influence-permissions/lib/permissions';
export var Media = createObjectPrototype({
  implements: [IMedia],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'Media';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'customerService', 'member', 'anonymous'],
      // TODO: Anon should not be alowed to create image
      read: ['owner', 'admin', 'customerService', 'anonymous'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin', 'customerService']
    });
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Media',
  getPrototype: function getPrototype() {
    return Media;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};
    return new Media(data);
  }
});