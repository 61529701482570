import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { i18n, SelectField, TextField } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IGalleryLayoutField = new Interface({
  name: 'IGalleryLayoutField'
});
export var GalleryLayoutField = createObjectPrototype({
  implements: [IGalleryLayoutField],
  extends: [SelectField],
  constructor: function constructor(options) {
    if (!options) {
      options = {};
    }

    options.valueType = new TextField({}); // We can override value options when using this field

    if (!options.options) {
      options.options = [{
        name: 'one',
        title: i18n('ICard-layoutType-option-one', 'Single')
      }, {
        name: 'two',
        title: i18n('ICard-layoutType-option-two', 'Two, side by side')
      }, {
        name: 'one_two',
        title: i18n('ICard-layoutType-option-one_two', 'Three, one on top')
      }, {
        name: 'one_three',
        title: i18n('ICard-layoutType-option-one_three', 'Four, one on top')
      }, {
        name: 'one_two_horizontal',
        title: i18n('ICard-layoutType-option-one_two_horizontal', 'Three, one on left')
      }, {
        name: 'one_four_horizontal',
        title: i18n('ICard-layoutType-option-one_four_horizontal', 'Five, one on left')
      }];
    }

    this._ISelectField.constructor.call(this, options);
  },
  validate: function validate(inp) {
    var error = this._ISelectField.validate.call(this, inp);

    if (error) {
      return error;
    }
  }
});
export var maxImagesByLayoutType = {
  'one': 1,
  'two': 2,
  'one_two': 3,
  'one_three': 4,
  'one_two_horizontal': 3,
  'one_four_horizontal': 5
};