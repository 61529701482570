import { Component } from 'inferno'
import { safeGet } from 'safe-utils'

import { getMobiledocRenderer } from 'influence-ux-mobiledoc'

import PublTemplate from './PublTemplate'

import { IApiClient, IAnalytics, IPageManager, ISessionManager } from 'influence-interfaces/presentation'

import './BookingPage.scss'
import mainImageJpg from '../../img/content/main_image.jpg'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class BookingPage extends Component {

    constructor(props) {
      super(props)
      this.renderer = getMobiledocRenderer()
    }

    static async fetchData ({registry, match, location}) {
      const pageName = 'boka-tid'
      const { data } = await new IApiClient({ registry })
        .query([
          { URI: `/public/staff` },
          { URI: `/public/Page/${pageName}` },
          { URI: `/public/Page/en-trappa-ner` },
        ])

      const page = safeGet(() => data[1])
      new IPageManager({ registry }).setMetaData({
        title: 'Kameleonten - Bokningar',
        description: safeGet(() => page.intro),
        url: FRONTEND_BASE_URI + location.pathname
      })

      return data
    }

    render ({ fetchData }, state, { router }) {
      const [staff, page, pageDownstairs] = fetchData || []
      const { params } = router.route.match
      
      return (
          <PublTemplate
            key={params.id || 'start'}
            mainImgUrl={safeGet(() => page.headerImage) || mainImageJpg}
            pageDownstairs={pageDownstairs}
            staff={staff}>
              <article className="publIntro">
                <h1>{safeGet(() => page.title)}</h1>
                <p>{safeGet(() => page.intro)}</p>
              </article>
              {this.renderer.render(safeGet(() => page.body))}
              <iframe className="publBookingIframe" src="https://www.bokadintid.se/booking4/?s_id=116"></iframe>
            </PublTemplate>
      )
    }
}
