import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["children", "handler", "className"];
import { createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import classNames from 'classnames';
import { UI } from 'mobiledoc-kit';
import { Button } from 'influence-ux-components';
export var LinkButton = function LinkButton(_ref, _ref2) {
  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? "Link" : _ref$children,
      handler = _ref.handler,
      className = _ref.className,
      props = _objectWithoutProperties(_ref, _excluded);

  var editor = _ref2.editor,
      _ref2$activeMarkupTag = _ref2.activeMarkupTags,
      activeMarkupTags = _ref2$activeMarkupTag === void 0 ? [] : _ref2$activeMarkupTag;

  var onClick = function onClick(e) {
    e && e.preventDefault();

    if (!editor.hasCursor()) {
      return;
    }

    if (editor.hasActiveMarkup('a')) {
      editor.toggleMarkup('a');
    } else {
      UI.toggleLink(editor, handler);
    }
  };

  className = classNames(className, {
    active: activeMarkupTags.indexOf('a') > -1
  });
  props = _objectSpread(_objectSpread({}, props), {}, {
    onClick: onClick,
    className: className
  });
  return normalizeProps(createComponentVNode(2, Button, _objectSpread(_objectSpread({
    "link": true
  }, props), {}, {
    children: children
  })));
};
/*
LinkButton.propTypes = {
  children: PropTypes.node
}

LinkButton.contextTypes = {
  editor: PropTypes.object,
  activeMarkupTags: PropTypes.array
}
*/