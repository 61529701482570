import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, createVNode(32, "g", null, [createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "0",
    "y": "90",
    "width": "53",
    "height": "60"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "0",
    "y": "0",
    "width": "180",
    "height": "80"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "127",
    "y": "90",
    "width": "53",
    "height": "60"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "63",
    "y": "90",
    "width": "54",
    "height": "60"
  })], 4, {
    "id": "icon_gallery_one_three",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  }), 2, {
    "width": "180px",
    "height": "150px",
    "viewBox": "0 0 180 150"
  });
}