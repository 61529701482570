var Interface = require('component-registry').createInterfaceClass('influence-interfaces');
/*
    A user in the system must implement IPrincipal to get the _principalId 
    that is used to determine ownership and creation etc.

    So whenever we get the IDataService we first try to get an adapter and 
    then a Utility, the latter used for anonymous users, the prior for logged
    in users.
*/


module.exports.IPrincipal = new Interface({
  name: "IPrincipal",
  principalId: "string"
});

module.exports.IPrincipal.prototype.permissions = function () {};

module.exports.IRootPrincipal = new Interface({
  name: "IRootPrincipal",
  principalId: "string"
});
module.exports.IAnonymousPrincipal = new Interface({
  name: "IAnonymousPrincipal",
  principalId: "string"
});