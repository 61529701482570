import { createComponentVNode } from "inferno";
import { i18n } from 'influence-i18n';
import { ButtonBar, StateButton } from 'influence-ux-components';

function WorkflowActions(_ref) {
  var obj = _ref.obj,
      propName = _ref.propName,
      workflowStates = _ref.workflowStates,
      doChangeState = _ref.doChangeState;
  return createComponentVNode(2, ButtonBar, {
    "className": "actions",
    "pills": true,
    "horizontal": "end",
    children: Object.keys(workflowStates).map(function (key) {
      // Don't return the current state since we don't want to transition to ourself
      var isActive = (obj._workflows && obj._workflows[propName]) === key;
      if (isActive) return null;
      return createComponentVNode(2, StateButton, {
        "link": true,
        "isSelected": isActive,
        "onClick": function onClick(e) {
          e.preventDefault();
          doChangeState(key);
        },
        children: i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)
      });
    })
  });
}

export { WorkflowActions };