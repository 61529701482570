import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'
import classnames from 'classnames'
import { Link } from 'inferno-router'
import {
  Body,
  Hero,
  Image,
  NavButton,
  Page,
} from 'influence-ux-components'

import { ResponsiveImage } from 'influence-ux-responsive-image'

// Get some frontend widgets
import { IPublMenuItem } from '../../widgets/app-entity-User'

import './PublTemplate.scss'
import logoSvg from '../../img/logo/logo-inverse.svg'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class PublTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      goBack: false
    }
  }

  doGoBack = (e) => {
    e.preventDefault()

    this.setState({
      goBack: true
    })
    requestAnimationFrame(() => {
      this.context.router.history.push('/')
    })
  }

  render ({ mainImgUrl, staff, page, pageDownstairs, children }, state, context) {
      const isSiteRoot = context.router.route.location.pathname === '/'
      const cls = { isSiteRoot }
      const { goBack } = state
      return (<Fragment>
          <Page className={classnames("publPage", cls)} animationPrefix={goBack ? "publPageNavBack" : "publPageNav"} animatedChildClass="publMain">
            <aside className="publNav">
              {page && (<div className="publContentNav">
                <Hero className="publMobileHero">
                  <Image className="publLogo" src={logoSvg} />
                  {typeof mainImgUrl === 'object'
                    ? <ResponsiveImage image={mainImgUrl} />
                    : <Image src={mainImgUrl} />}
                </Hero>
                <Body className="publIntro">
                  <h1>{safeGet(() => page.title)}</h1>
                  <p>{safeGet(() => page.intro)}</p>
                </Body>
              </div>)}
              <nav>
                <ul>
                  <li className="publNavStart"><NavButton to="/">Start</NavButton></li>
                  <li><NavButton to="/boka-tid">Boka</NavButton></li>
                  <li><NavButton to="/priser">Priser</NavButton></li>
                  <li><NavButton to="/tape-extensions">Hårförlängning</NavButton></li>
                  <li><NavButton to="/om-salongen">Om salongen</NavButton></li>
                  {pageDownstairs && <li><NavButton to="/en-trappa-ner">{pageDownstairs.title}</NavButton></li>}
                  <li><h2>Teamet</h2></li>
                  {Array.isArray(staff) && staff.map((s) => {
                    const PublMenuItem = new IPublMenuItem(s).Component
                    return <li><PublMenuItem context={s} /></li>
                  })}
                </ul>
              </nav>
            </aside>
            <main className="publMain">
              {!isSiteRoot && <nav className="publNavBack"><NavButton onClick={this.doGoBack}>Tillbaka</NavButton></nav>}
              <Hero className="publHero">
                <Image className="publLogo" src={logoSvg} />
                {typeof mainImgUrl === 'object'
                  ? <ResponsiveImage image={mainImgUrl} />
                  : <Image src={mainImgUrl} />}
              </Hero>
              <div className="publContent">
                {children}
                {this.renderFooter()}
              </div>
              <div className="mobileFooter">
                {this.renderFooter()} 
              </div>
            </main>
          </Page>
      </Fragment>)
    }

    renderFooter() {
      return (
        <footer className="publFooter">
          <nav>
            <ul>
              <li><Link to="/allmanna-villkor">Allmänna villkor</Link></li>
              <li><Link to="/arbeta-hos-oss">Arbeta hos oss</Link></li>
              <li><a href="https://www.facebook.com/salongkameleonten">Facebook</a></li>
              <li><a href="https://www.instagram.com/salongkameleonten/">Instagram</a></li>
              <li><a href="https://www.reco.se/kameleonten-stockholm">Reco.se</a></li>
              <li><a href="https://thatsup.se/stockholm/frisor/kameleonten/">thatsup.se</a></li>
            </ul>
          </nav>
        </footer>
      )
    }
}
