import { createObjectPrototype, Utility } from 'component-registry';
import { IUser } from './interface';
import { UserWorkflow } from 'app-workflow-UserWorkflow';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { Permissions } from 'influence-permissions/lib/permissions';
import { Principal } from 'influence-permissions/lib/principals';
export var User = createObjectPrototype({
  implements: [IUser],
  extends: [Permissions, Principal, UserWorkflow],
  constructor: function constructor(params) {
    this._type = 'User';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'editor'],
      read: ['owner', 'admin', 'customerService'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin']
    });

    this._IPrincipal.constructor.call(this, params);

    this._IUserWorkflow.constructor.call(this, params, {
      defaultState: 'active'
    });
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'User',
  getPrototype: function getPrototype() {
    return User;
  },
  getObject: function getObject(data) {
    return new User(data);
  }
});