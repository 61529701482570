import './config'
import { Redirect } from 'inferno-router'
import { ApiClient, App, doAllAsyncBefore, injectTypeParam, RestishRoute} from 'influence-App'
import 'app-external-googleAnalytics/app'

// TODO: Import Pages
// TODO: Consider creating base admin pages in influence-App to make code more readable

/**
 * Register i18n utilities
 */
import './i18n'

/**
 * Entities
 */
import 'app-entity-AccountEmail'
import 'app-entity-AccountFacebook'
import 'app-entity-AccountInstagram'
import 'app-entity-Invitation'
import 'app-entity-Image'
import 'app-entity-Media'
import 'app-entity-Page'
import 'app-entity-ServiceProduct'
import 'app-entity-User'

/**
 * Media libraries
 */
import 'app-widget-MediaLibrary';

/**
 * Pages
 */

import DashboardPage from './pages/admin/DashboardPage'
import ListPage from './pages/admin/ListPage'
import CreatePage from './pages/admin/CreatePage'
import EditPage from './pages/admin/EditPage'
import PagesAndSnippets from './pages/admin/PagesAndSnippets'

import RegisterStep1 from './pages/onboarding/RegisterStep1'
import RegisterStep2 from './pages/onboarding/RegisterStep2'
import RegisterStep3 from './pages/onboarding/RegisterStep3'
import RegisterStep4 from './pages/onboarding/RegisterStep4'

import StartPage from './pages/public/StartPage'
import AboutPage from './pages/public/AboutPage'
import BookingPage from './pages/public/BookingPage'
import PricePage from './pages/public/PricePage'
import ContentPage from './pages/public/ContentPage'
import StaffPage from './pages/public/StaffPage'

/**
 * App CSS-styling
 */
import 'influence-ux-components/src/index.scss'
import 'influence-ux-formlib/src/index.scss'
import 'influence-App/src/index.scss'
import './cssVariables.scss'
import './cssTypography.scss'
import './cssCommon.scss'

/**
 * Image resources
 */
import logo_colored_svg from './img/logo/logo-payoff-colored.svg'

function appFactory () {

  // TODO: Pass logo to <App>

  return (
      <App
        defaultPageMetaData={{
          title: 'Kameleonten - Salongen med det extra lyxiga',
          description: 'Kameleonten - Salongen med det extra lyxiga',
        }}
        logoSvg={logo_colored_svg}>

        {/* Admin Pages */}
        <RestishRoute exact section="admin" path={`/admin`} component={ DashboardPage } asyncBefore={ DashboardPage.fetchData } />
        <RestishRoute exact section="admin" path={`/admin/:type/create`} component={ CreatePage } asyncBefore={ CreatePage.fetchData } />
        <RestishRoute exact section="admin" path={`/admin/dashboard`} component={ DashboardPage } asyncBefore={ DashboardPage.fetchData } />
        <RestishRoute section="admin" path={`/admin/page`} component={ PagesAndSnippets } asyncBefore={ injectTypeParam('page', PagesAndSnippets.fetchData) } />
        <RestishRoute section="admin" path={`/admin/:type`} component={ ListPage } asyncBefore={ ListPage.fetchData } />
        <RestishRoute section="admin" path={`/admin/:type/:id`} component={ EditPage } asyncBefore={ EditPage.fetchData } />

        {/* Admin User Onboarding */}
        <RestishRoute public exact section="onboarding" exact path={`/invite/:id`} component={ RegisterStep1 } asyncBefore={ injectTypeParam('Invitation', RegisterStep1.fetchData) } />
        <RestishRoute exact section="onboarding" path={`/register_2`} component={ RegisterStep2 } asyncBefore={ RegisterStep2.fetchData } />
        <RestishRoute exact section="onboarding" path={`/register_3`} component={ RegisterStep3 } asyncBefore={ RegisterStep3.fetchData } />
        <RestishRoute exact section="onboarding" path={`/register_4`} component={ RegisterStep4 } asyncBefore={ RegisterStep4.fetchData } />
        
        {/* Public Site */}
        <RestishRoute public exact path={`/`} component={ StartPage } asyncBefore={StartPage.fetchData} />
        <RestishRoute public exact path={`/start`} component={ StartPage } asyncBefore={StartPage.fetchData} />
        <RestishRoute public exact path={`/om-salongen`} component={ AboutPage } asyncBefore={AboutPage.fetchData} />
        <RestishRoute public exact path={`/boka-tid`} component={ BookingPage } asyncBefore={BookingPage.fetchData} />
        <RestishRoute public exact path={`/priser`} component={ PricePage } asyncBefore={PricePage.fetchData} />
        {/*<RestishRoute public exact path={`/allmanna-villkor`} component={ ContentPage } asyncBefore={ContentPage.fetchData} />*/}
        {/*<RestishRoute public exact path={`/arbeta-hos-oss`} component={ ContentPage } asyncBefore={ContentPage.fetchData} />*/}
        <RestishRoute public exact path={`/:pageName`} component={ ContentPage } asyncBefore={ContentPage.fetchData} />
        <RestishRoute public exact path={'/frisor/:pageName/:slug?'} component={ StaffPage } asyncBefore={StaffPage.fetchData} />
        
        <Redirect to="/" />
      </App>
  )
}

export { 
  appFactory,
  doAllAsyncBefore,
  ApiClient
}