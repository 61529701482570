import { createComponentVNode } from "inferno";
import classnames from 'classnames';
import NavStateButton from '../buttons/NavStateButton';
import ButtonBar from '../buttons/ButtonBar';
/**
 * 
 * @param {String} param0.className
 * @param {object[]} param0.options filter options list [{isSelected, label, to}]
 */

export default function (_ref) {
  var className = _ref.className,
      options = _ref.options;
  return createComponentVNode(2, ButtonBar, {
    "className": classnames('Filter', className),
    children: options.map(function (item) {
      return createComponentVNode(2, NavStateButton, {
        "isSelected": item.isSelected,
        "to": item.to,
        children: item.label
      });
    })
  });
}