import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { BoolField, i18n, IntegerField, Schema, TextField } from 'isomorphic-schema';
import { MobiledocField } from 'app-field-MobiledocField/field';
export var IServiceProduct = new Interface({
  name: 'IServiceProduct',
  schema: new Schema('ServiceProduct Schema', {
    title: new TextField({
      label: i18n('IServiceProduct-title-label', 'Title'),
      placeholder: i18n('IServiceProduct-title-placeholder', 'Type here...'),
      require: true
    }),
    fromPrice: new BoolField({
      label: i18n('IServiceProduct-fromPrice-label', 'Pris från'),
      help: i18n('IServiceProduct-fromPrice-help', 'Lägger till "från" framför priset')
    }),
    priceSEK: new IntegerField({
      label: i18n('IServiceProduct-priceSEK-label', 'Pris i kronor'),
      placeholder: i18n('IServiceProduct-priceSEK-placeholder', 'Endast heltal...'),
      require: true
    })
  })
});