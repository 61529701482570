import { globalRegistry, Utility, createObjectPrototype } from 'component-registry';
import { IPrincipal, IRootPrincipal, IAnonymousPrincipal } from 'influence-interfaces/principal';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
export var Principal = createObjectPrototype({
  implements: [IPrincipal],
  constructor: function constructor(params) {
    this._principalId = params.principalId;

    if (params.principalId) {
      delete params.principalId;
    }
  },
  permissions: function permissions() {
    return this.roles;
  }
});
var PrincipalFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Principal',
  getPrototype: function getPrototype() {
    return Principal;
  },
  getObject: function getObject(obj) {
    return new Principal(obj);
  }
}); // The root principal is used to do really deep shit! 

var RootPrincipal = createObjectPrototype({
  implements: [IRootPrincipal, IPrincipal],
  constructor: function constructor() {
    this._principalId = "root";
    this._id = "root";
    this._type = "RootPrincipal";
  },
  permissions: function permissions() {
    return 'admin';
  }
});
var RootPrincipalFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'RootPrincipal',
  getPrototype: function getPrototype() {
    return RootPrincipal;
  },
  getObject: function getObject() {
    return new RootPrincipal();
  }
}); // The anonymous principal is used to do do public access for none logged in users

var AnonymousPrincipal = createObjectPrototype({
  implements: [IAnonymousPrincipal, IPrincipal],
  constructor: function constructor() {
    this._principalId = "anonymous";
    this._id = "anonymous";
    this._type = "AnonymousPrincipal";
  },
  permissions: function permissions() {
    return ['anonymous'];
  }
});
var AnonymousPrincipalFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'AnonymousPrincipal',
  getPrototype: function getPrototype() {
    return AnonymousPrincipal;
  },
  getObject: function getObject() {
    return new AnonymousPrincipal();
  }
});