"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Permissions = void 0;

var _componentRegistry = require("component-registry");

var _assert = _interopRequireDefault(require("assert"));

var _permissions = require("influence-interfaces/permissions");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Permissions = (0, _componentRegistry.createObjectPrototype)({
  implements: [_permissions.IPermissions],
  constructor: function constructor(params, permissions) {
    // We want to throw an error if no permissions are passed
    (0, _assert.default)(typeof params._permissions === 'object' || typeof permissions === 'object', 'No permissions passed to Permissions constructor');

    if (permissions) {
      // Set permissions if they are specifically passed in the constructor
      if (!this._permissions) this._permissions = {};
      var keys = Object.keys(permissions);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        this._permissions[key] = permissions[key].map(function (perm) {
          return params._id ? perm.replace('{id}', params._id) : perm;
        });
      }
    } else {
      // Otherwise use what we get from params
      this._permissions = params._permissions;
    } // Make sure we have an owners array


    this._permissions.owners = params && params._permissions && params._permissions.owners || []; // Cleanup

    if (params && params.hasOwnProperty('_permissions')) {
      delete params._permissions;
    }
  }
});
exports.Permissions = Permissions;