import { Component } from 'inferno'
import classnames from 'classnames'
import { safeGet } from 'safe-utils'
import { 
  Body,
  Button,
  ButtonBar,
  Footer,
  FormRow,
  Header,
  Label,
  Modal,
  Toolbar,
} from 'influence-ux-components'

import { BoolField } from 'isomorphic-schema'
import { Animated } from 'inferno-animation'
import { widgets } from 'influence-ux-formlib'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField'

import { IApiClient, INotificationManager, ISessionManager } from 'influence-interfaces/presentation'
import { IUser } from 'app-entity-User'

import './RegisterStep.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

const { CheckboxWidget } = widgets

const acceptField = new BoolField({
  label: 'Jag accepterar villkoren'
})


export default class Page extends Component {

  constructor (props) {
    super(props)

    this.state = {
      modal: false,
      accepted: false
    }
  }

  static async fetchData ({registry, match, location}) {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/session`
      })
      
      return data
  }

  render () {
      const currentUser = safeGet(() => new ISessionManager().getCurrentUser())
      return (
        <Animated className="Fullpage Onboarding" key="onboarding-2" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
          {this.renderModal()}
          <img id="Page-Logo" src={logo_colored_svg} />
          {this.renderAvatar()}
          <div className="Onboarding-Content">
            <Body>
                  <h2>Allmänna villkor</h2>
                  <p>Syftet med denna sida är att marknadsföra Salong Kameleonten. Som användare tar du ansvar för att det material du publicerar följer lagen och bidrar på ett positivt sätt till bilden av salongen.</p>
                  <p>Om du kopplar externa konton, som t.ex. Instagram, accepterar du att vi publicerar innehåll därifrån på den här sidan. Du kan när som helst be administratören av denna sida att dölja ditt innehåll. Material plockas då bort i samband med generell administration av sidan.</p>
            </Body>
            {currentUser && 
              <Footer>
                <Toolbar className="Onboarding-AcceptTerms"
                  leading={(
                    <div className="_InputContainer">
                      <FormRow>
                        <Label for="accept__Field">
                          <CheckboxWidget namespace={["accept"]} value={this.state.accepted} adapter={{ context: acceptField }} onChange={this.didChange}/>
                          {acceptField.label}
                        </Label>
                      </FormRow> 
                    </div>
                  )}
                  trailing={(
                    <div className="_ButtonContainer">
                      <Button
                        className={classnames("Onboarding-Button Onboarding-Action-Button", { "fake-disabled": !this.state.accepted })}
                        onClick={this.doNext}>Next...</Button>
                    </div>
                  )} />
              </Footer>
            }
          </div>
        </Animated>
      )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    if (!currentUser) return null

    const propName = 'avatarImage'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      readOnly
      adapter={dummyAdapter}
      value={currentUser[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  renderModal () {
    return (
      <Modal fade className="MessageModal" isOpen={this.state.modal} doClose={this.doCloseModal}>
        <Header toggle={this.doCloseModal}>Oops!</Header>
        <Body>
          <p>Du måste godkänna vilkoren genom att klicka på checkboxen.</p>
        </Body>
        <Footer>
          <ButtonBar>
            <Button primary onClick={this.doCloseModal}>Ok</Button>
          </ButtonBar>
        </Footer>
      </Modal>
    )
  }


  doNext = (e) => {
    e.preventDefault()
    if (this.state.accepted) {
      const currentUser = new ISessionManager().getCurrentUser()
      currentUser.termsAndConditions = {
        name: 'onboarding-terms',
        dateTime: new Date().toUTCString()
      }
      new IApiClient().update({
        URI: `/admin/User/${currentUser._id}`,
        data: currentUser,
        invalidate: ['/admin/User', '/session']
      }).then(async ({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        await new ISessionManager().refreshCurrentUser()
        new INotificationManager().showSuccessMessage()
        this.context.router.history.push(`/register_3`)
      })
    } else {
      this.setState({
        modal: true
      })
    }
  }

  didChange = (propName, value) => {
    this.setState({
      accepted: value
    })
  }

  doCloseModal = () => {
    this.setState({
      modal: false
    })
  }

  doOpenModal = () => {
    this.setState({
      modal: true
    })
  }
}

