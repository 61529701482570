import { createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { ButtonBar, StateButton } from 'influence-ux-components';
import { IGalleryLayoutField } from './field';
import { interfaces } from 'influence-ux-formlib';
var IInputFieldWidget = interfaces.IInputFieldWidget;
import galleryOne from './icons/gallery_one';
import galleryTwo from './icons/gallery_two';
import galleryOneTwo from './icons/gallery_one_two';
import galleryOneThree from './icons/gallery_one_three';
import galleryOneTwoHorizontal from './icons/gallery_one_two_horizontal';
import galleryOneFourHorizontal from './icons/gallery_one_four_horizontal';
import './widget.scss';
var icons = {
  one: galleryOne,
  two: galleryTwo,
  one_two: galleryOneTwo,
  one_three: galleryOneThree,
  one_two_horizontal: galleryOneTwoHorizontal,
  one_four_horizontal: galleryOneFourHorizontal
};

function renderIcon(name) {
  var Icon = icons[name];
  return createComponentVNode(2, Icon);
}

export default function GalleryLayoutFieldWidget(props) {
  var field = props.adapter.context;
  return createComponentVNode(2, ButtonBar, {
    "className": "GalleryLayoutField",
    children: field.options.map(function (opt) {
      return createComponentVNode(2, StateButton, {
        "isSelected": props.value === opt.name,
        "onClick": function onClick(e) {
          e.preventDefault();
          props.onChange(props.propName, opt.name);
        },
        children: renderIcon(opt.name)
      });
    })
  });
}
new Adapter({
  implements: IInputFieldWidget,
  adapts: IGalleryLayoutField,
  Component: GalleryLayoutFieldWidget
});
export { IGalleryLayoutField, GalleryLayoutFieldWidget };