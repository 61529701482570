export function renderTimedPublishingString(startsAt, endsAt) {
  var outp = [];

  if (startsAt instanceof Date && !isNaN(startsAt.getTime())) {
    var tmp = startsAt.toLocaleString();
    outp.push("".concat(tmp.substr(0, 10), " ").concat(tmp.substr(11, 5), " "));
  }

  if (endsAt instanceof Date && !isNaN(endsAt.getTime())) {
    var _tmp = endsAt.toLocaleString();

    outp.push(" ".concat(_tmp.substr(0, 10), " ").concat(_tmp.substr(11, 5)));
  }

  if (outp[0] || outp[1]) {
    return "".concat(outp[0] || '', "-").concat(outp[1] || '');
  }
}
export function hasTimedPublishing(obj) {
  return obj.publishingStartsAt instanceof Date || obj.publishingEndsAt instanceof Date;
}