import { ITranslationUtil, ITranslationLang } from 'influence-interfaces/i18n';
/**
 * Translate label to current language.
 * @param {*} label - i18n label 
 * @param {*} fallback - fallback text if no translation is found
 */

export function i18n(label, fallback) {
  // TODO: look up current language in a performant way if generalised
  var lang = new ITranslationLang().lang();
  return new ITranslationUtil().message(label, lang, fallback);
}