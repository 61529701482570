import { createObjectPrototype, Utility } from 'component-registry';
import { IAccount } from 'app-entity-Account/interface';
import { IAccountInstagram } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
export var AccountInstagram = createObjectPrototype({
  implements: [IAccountInstagram, IAccount],
  constructor: function constructor(params) {
    this._type = 'AccountInstagram';
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'AccountInstagram',
  getPrototype: function getPrototype() {
    return AccountInstagram;
  },
  getObject: function getObject(data) {
    return new AccountInstagram(data);
  }
});