import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/lib/permissions';
import { InvitationWorkflow } from 'app-workflow-InvitationWorkflow';
import { IInvitation } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin'],
  read: ['admin', 'owner', 'anonymous:invitationWorkflow.pending', 'anonymous:invitationWorkflow.activated'],
  update: ['admin', 'owner', 'anonymous:invitationWorkflow.pending'],
  delete: ['admin', 'owner']
};
export var Invitation = createObjectPrototype({
  implements: [IInvitation],
  extends: [Permissions, InvitationWorkflow],
  constructor: function constructor(params, roleManager) {
    this._type = 'Invitation';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IInvitationWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Invitation',
  getPrototype: function getPrototype() {
    return Invitation;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};
    return new Invitation(data);
  }
});