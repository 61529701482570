var Interface = require('component-registry').createInterfaceClass('influence-interfaces');

export var IMobiledocCardUtil = new Interface({
  name: 'IMobiledocCardUtil'
});
IMobiledocCardUtil.prototype.type = String;
IMobiledocCardUtil.prototype.RenderComponent = Object;
IMobiledocCardUtil.prototype.EditComponent = Object;
export var IMobiledocAtomUtil = new Interface({
  name: 'IMobiledocAtomUtil'
});
IMobiledocAtomUtil.prototype.type = String;

IMobiledocAtomUtil.prototype.RenderComponent = function () {};

IMobiledocAtomUtil.prototype.Component = function () {};