import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", "icon", [createVNode(32, "defs", null, createVNode(32, "path", null, null, 1, {
    "d": "M115.49906,-1.97619698e-14 C118.387483,-1.97619698e-14 120.857866,1.04003452 122.914236,3.12091864 C124.885696,5.11509925 125.912499,7.4947915 125.994643,10.2614301 L126,10.6244906 L126,91.3746943 C126,94.2967349 124.971412,96.7981972 122.915042,98.8790814 C120.944354,100.873262 118.592695,101.911523 115.859358,101.994583 L115.500671,102 L10.5001342,102 C7.61251678,102 5.14132839,100.959965 3.08415266,98.8790814 C1.11346449,96.8849008 0.0874342757,94.5044599 0.00535309157,91.7377589 L-2.54241073e-14,91.3746943 L-2.54241073e-14,10.6244906 C-2.54241073e-14,7.70245002 1.02778239,5.20180275 3.08415266,3.12091864 C5.05561273,1.12673803 7.40730319,0.0884765739 10.1413508,0.00541690542 L10.5001342,-1.97619698e-14 L115.49906,-1.97619698e-14 Z M115,3 L11,3 C6.581722,3 3,6.581722 3,11 L3,11 L3,91 C3,95.418278 6.581722,99 11,99 L11,99 L115,99 C119.418278,99 123,95.418278 123,91 L123,91 L123,11 C123,6.581722 119.418278,3 115,3 L115,3 Z M85.3870844,25 L117,56.7778472 L117,91 L10,91 L10,76.3332292 L34.3178426,51.8892361 L46.4772303,64.1107639 L85.3870844,25 Z M28.4996004,17 C31.9725108,17 34.9236056,18.2154387 37.354483,20.645517 C39.7837622,23.0763944 41,26.0274892 41,29.5003996 C41,32.9725108 39.7845613,35.9236056 37.354483,38.354483 C34.9236056,40.7853604 31.9725108,42 28.4996004,42 C25.0274892,42 22.0763944,40.7845613 19.645517,38.354483 C17.2146396,35.9244047 16,32.9725108 16,29.5003996 C16,26.0274892 17.2154387,23.0763944 19.645517,20.645517 C22.0763944,18.2154387 25.0274892,17 28.4996004,17 Z",
    "id": "image-1"
  }), 2), createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "xlink:href": "#image-1"
  }), 2, {
    "id": "image-mask-2",
    "fill": "white"
  }), createVNode(32, "g", null, null, 1, {
    "id": "image-1"
  }), createVNode(32, "g", null, createVNode(32, "g", null, createVNode(32, "rect", null, null, 1, {
    "x": "0",
    "y": "0",
    "width": "144.683544",
    "height": "144.285714"
  }), 2, {
    "transform": "translate(-8.841772, -21.642857)"
  }), 2, {
    "mask": "url(#image-mask-2)",
    "fill": "currentColor",
    "fill-rule": "nonzero"
  })], 4, {
    "transform": "translate(0, 15)"
  }), 2, {
    "id": "image",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  })], 4, {
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 126 126"
  });
}