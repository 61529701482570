import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, createVNode(32, "g", null, createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "circle", null, null, 1, {
    "id": "Oval",
    "cx": "50",
    "cy": "50",
    "r": "50"
  }), createVNode(32, "path", null, null, 1, {
    "d": "M53.2475235,64.1054042 L53.2475235,19.1054042 L62.2475235,19.1054042 L62.2475235,64.1054042 L62.2475235,73.1054042 L37.2475235,73.1054042 L37.2475235,64.1054042 L53.2475235,64.1054042 Z",
    "id": "Combined-Shape",
    "transform": "translate(49.747524, 46.105404) rotate(44.000000) translate(-49.747524, -46.105404) "
  })], 4, {
    "id": "icon_ok",
    "transform": "translate(455.000000, 404.000000)"
  }), 2, {
    "id": "Steg-3",
    "transform": "translate(-455.000000, -404.000000)"
  }), 2, {
    "id": "Page-2",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  }), 2, {
    "width": "100px",
    "height": "100px",
    "viewBox": "0 0 100 100",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg"
  });
}