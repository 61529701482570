import _typeof from "@babel/runtime/helpers/typeof";
import { globalRegistry } from 'component-registry';
import { ITranslationUtil } from 'influence-interfaces/i18n'; // Get unnamed translation utility or return undefined

export function renderString(label, lang, fallbackLabel) {
  if (_typeof(label) === 'object') {
    return label['i18n'];
  }

  var i18n;

  try {
    i18n = new ITranslationUtil();
  } catch (e) {// Do nothing
  }

  return i18n && label ? i18n.message(label, lang) || label : fallbackLabel || label;
}
export function renderVariables(field, text) {
  if (field === undefined) {
    return text;
  }

  var outp = text;
  Object.getOwnPropertyNames(field).forEach(function (key) {
    // This should be faster than dynamic regex
    // https://jsperf.com/replace-vs-split-join-vs-replaceall/23
    var tmp = key[0] === '_' ? key.substring(1) : key;
    outp = outp.split("${".concat(tmp, "}")).join(field[key]);
  });
  return outp;
}