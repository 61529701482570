import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, IntegerField, ObjectField, ListField, TextField, i18n } from 'isomorphic-schema';
var fileInstanceSchema = new Schema('File Instance Schema', {
  width: new IntegerField({}),
  height: new IntegerField({}),
  imagePath: new TextField({})
});
var IImage = new Interface({
  name: 'IImage',
  schema: new Schema('Image Schema', {
    rawImage: new ObjectField({
      schema: fileInstanceSchema
    }),
    fileType: new TextField({
      label: i18n('IImage-fileType-label', 'Type') // readOnly: true

    }),
    rendered: new ListField({
      label: i18n('IImage-rendered-label', 'Rendered Images'),
      valueType: new ObjectField({
        schema: fileInstanceSchema
      })
    }),
    thumbnails: new ListField({
      label: i18n('IImage-thumbnails-label', 'Thumbnail Images'),
      valueType: new ObjectField({
        schema: fileInstanceSchema
      })
    })
  })
});
export { IImage };