import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { Animated } from 'inferno-animation'
import { 
  Button,
  Footer,
  Form,
  Section,
  Toolbar,
} from 'influence-ux-components'
import { FormRows } from 'influence-ux-formlib'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField'

import { IApiClient, INotificationManager, ISessionManager } from 'influence-interfaces/presentation'
import { IUser } from 'app-entity-User'

import 'app-field-ImageField'
import 'app-field-AvatarImageField/index.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

const selectFields = ['firstName', 'lastName', 'title', 'description']
const omitFields = []


export default class Page extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: new ISessionManager({ registry: context.registry }).getCurrentUser() || {},
      validationErrors: undefined,
    }
  }

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/session`
    })
    
    return data
  }

  componentDidUpdate(prevProps, prevState) {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    if (safeGet(() => prevState.value._id !== currentUser._id)) {
      this.setState({
        value: currentUser
      })
    }
  }

  render (props, state, context) {
    return (
      <Animated className="Fullpage Onboarding" key="onboarding-3" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
        <img id="Page-Logo" src={logo_colored_svg} />
        {this.renderAvatar()}
        <div className="Onboarding-Content">
          <Section>
            <p>Nu behöver vi komplettera med lite information till din profil på Kameleonten.</p>
          </Section>
          <Section>
            <Form onSubmit={this.doSubmit} className='IEditItem'>
              <FormRows
                schema={IUser.schema}
                validationErrors={this.state.validationErrors}
                value={this.state.value}
                selectFields={selectFields}
                omitFields={omitFields}
                onChange={this.didUpdate} />
            </Form>
          </Section>
          <Footer>
            <Toolbar className="Onboarding-AcceptTerms"
              leading={(
                <Button
                className="Onboarding-Button Onboarding-Action-Button"
                onClick={this.doSubmit} href="#instagram">Next...</Button>
              )} />
          </Footer>
        </div>
      </Animated>
    )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    if (!currentUser) return null

    const propName = 'avatarImage'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      adapter={dummyAdapter}
      value={currentUser[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  didUpdate = (propName, value) => {
    const newVal = this.state.value
    newVal[propName] = value

    let validationErrors
    if (this.state.submitted) {
      validationErrors = IUser.schema.validate(this.state.value, {
        selectFields,
        omitFields
      })
    }

    this.setState({
        value: newVal,
        validationErrors
    })
  }

  doSubmit = (e) => {
    e.preventDefault()

    let validationErrors = IUser.schema.validate(this.state.value, {
      selectFields,
      omitFields
    })
    if (validationErrors) {
      return this.setState({
        validationErrors,
        submitted: true
      })
    }
    
    new IApiClient().update({
        URI: `/admin/User/${new ISessionManager().getCurrentUser()._id}`,
        data: this.state.value,
        invalidate: ['/admin/User', '/session']
    }).then(async ({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        await new ISessionManager().refreshCurrentUser()
        new INotificationManager().showSuccessMessage()
        this.context.router.history.push(`/register_4`)
    })
  }
}

