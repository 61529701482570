import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, BoolField, ObjectField, PasswordField, TextField, TextAreaField, DateTimeField, i18n } from 'isomorphic-schema'; // Instagram auth docs
// https://www.instagram.com/developer/authentication/
// Scopes: https://www.instagram.com/developer/authorization/
// We probablt need these scopes: basic, public_content, comments, relationships, likes, follower_list

var facebookUserSchema = new Schema('Facebook User Schema', {
  id: new TextField({}),
  first_name: new TextField({}),
  last_name: new TextField({}),
  email: new TextField({})
});
var IAccountFacebook = new Interface({
  name: 'IAccountFacebook',
  schema: new Schema('AccountFacebook Schema', {
    useForLogin: new BoolField({
      label: i18n('IAccountFacebook-useForLogin-label', 'Use for login')
    }),
    accessToken: new PasswordField({
      label: i18n('IAccountFacebook-accessToken-label', 'Access Token')
    }),
    accessTokenExpiresAt: new DateTimeField({}),
    user: new ObjectField({
      label: i18n('IAccountFacebook-user-label', 'Basic User Data'),
      schema: facebookUserSchema
    }),
    // DEPRECATED! Consider removing...
    userId: new TextField({
      label: i18n('IAccountFacebook-userId-label', 'Facebook User ID')
    })
  })
});
export { IAccountFacebook };