import { createVNode } from "inferno";
export default function (_ref) {
  var className = _ref.className;
  return createVNode(32, "svg", className, createVNode(32, "g", "icon", createVNode(32, "path", null, null, 1, {
    "d": "M21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 Z M22.3787813,8.473 L19.6392187,8.473 L19.6392187,20.1032187 L8.009,20.1032187 L8.009,22.8427813 L19.6392187,22.8427813 L19.6392187,34.473 L22.3787813,34.473 L22.3787813,22.8427813 L34.009,22.8427813 L34.009,20.1032187 L22.3787813,20.1032187 L22.3787813,8.473 Z"
  }), 2, {
    "fill": "currentColor",
    "stroke": "none",
    "fill-rule": "nonzero"
  }), 2, {
    "width": "42px",
    "height": "42px",
    "viewBox": "0 0 42 42"
  });
}