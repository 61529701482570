import { createVNode } from "inferno";
export default function (_ref) {
  var className = _ref.className;
  return createVNode(32, "svg", className, createVNode(32, "g", "icon icon-transparent", createVNode(32, "path", null, null, 1, {
    "d": "M10.5,0 C16.2989899,0 21,4.70101013 21,10.5 C21,16.2989899 16.2989899,21 10.5,21 C4.70101013,21 -2.38742359e-12,16.2989899 -2.38742359e-12,10.5 C-2.38742359e-12,4.70101013 4.70101013,0 10.5,0 Z M11.1893907,4.2365 L9.81960934,4.2365 L9.81960934,10.0516093 L4.0045,10.0516093 L4.0045,11.4213907 L9.81960934,11.4213907 L9.81960934,17.2365 L11.1893907,17.2365 L11.1893907,11.4213907 L17.0045,11.4213907 L17.0045,10.0516093 L11.1893907,10.0516093 L11.1893907,4.2365 Z"
  }), 2, {
    "fill": "currentColor",
    "fill-rule": "nonzero",
    "stroke": "none",
    "transform": "translate(11, -4) rotate(45.000000)"
  }), 2, {
    "width": "22px",
    "height": "22px",
    "viewBox": "0 0 22 22"
  });
}