var Interface = require('component-registry').createInterfaceClass('influence-interfaces'); // Image related


export var IImageHelper = new Interface({
  name: 'IImageHelper'
});

IImageHelper.prototype.getSrcSet = function (maxWidth, maxHeight) {};

IImageHelper.prototype.getThumbSrcSet = function (maxWidth, maxHeight) {};

export var IMediaSelectItem = new Interface({
  name: 'IMediaSelectItem'
});
export var IMediaSelectDetails = new Interface({
  name: 'IMediaSelectDetails'
}); // Lists

export var IListItem = new Interface({
  name: 'IListItem'
});

IListItem.prototype.Component = function () {}; // CRUD forms


export var ICreateForm = new Interface({
  name: 'ICreateForm'
});

ICreateForm.prototype.Component = function () {};

export var IEditForm = new Interface({
  name: 'IEditForm'
});

IEditForm.prototype.Component = function () {};

export var IViewForm = new Interface({
  name: 'IViewForm'
});

IViewForm.prototype.Component = function () {};
/**
 * Public content
 */


export var IPublicForm = new Interface({
  name: 'IPublicForm'
});

IPublicForm.prototype.Component = function () {};

export var IPublicListItem = new Interface({
  name: 'IPublicListItem'
});

IPublicListItem.prototype.Component = function () {};

export var ILightbox = new Interface({
  name: 'ILightbox'
});

ILightbox.prototype.Component = function () {}; // Other UX related core features


export var INotificationManager = new Interface({
  name: 'INotificationManager'
}); // Get the API client for Restish

export var IApiClient = new Interface({
  name: 'IApiClient'
}); // Get the session manager to get current user etc.
// TODO: Add methods to interface

export var ISessionManager = new Interface({
  name: 'ISessionManager'
}); // Get the page manager to set meta data
// TODO: Add methods to interface

export var IPageManager = new Interface({
  name: 'IPageManager'
});
/**
 * @typedef ImageMetaData
 * @type {object}
 * @property {string} param0.url - image URI
 * @property {int} param0.width - image width in pixels
 * @property {int} param0.height - image height in pixels
 */

/**
 * @typedef MetaData
 * @type {object}
 * @property {string} param0.title
 * @property {string} param0.description
 * @property {ImageMetaData} param0.image - sharing image
 * @property {string} param0.url - canonical url
 */

/**
 * @param [MetaData] metaData - set meta data for page head
 */

IPageManager.prototype.setMetaData = function (metaData) {};

export var IAnalytics = new Interface({
  name: 'IAnalytics'
});
export var IMediaLibrary = new Interface({
  name: 'IMediaLibrary'
});

IMediaLibrary.prototype.Component = function () {};