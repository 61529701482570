import { createObjectPrototype, Utility } from 'component-registry';
import { IMediaCard } from './interface';
import { ICard } from 'app-card-Card';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { Permissions } from 'influence-permissions/dist/permissions';
var _permissions = {
  create: ['admin', 'customerService', 'member'],
  // TODO: Anon should not be alowed to create image
  read: ['owner', 'admin', 'customerService', 'anonymous:publishWorkflow.published'],
  update: ['owner', 'admin', 'customerService'],
  delete: ['owner', 'admin', 'customerService']
};
export var MediaCard = createObjectPrototype({
  implements: [IMediaCard, ICard],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'MediaCard';

    this._IPermissions.constructor.call(this, params, _permissions);

    this.layoutType = 'one';
    this.allowCrop = true;
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'MediaCard',
  getPrototype: function getPrototype() {
    return MediaCard;
  },
  getObject: function getObject(data) {
    data = data || {};
    return new MediaCard(data);
  }
});