import _typeof from "@babel/runtime/helpers/typeof";
import { Utility } from 'component-registry';
import { IDeserialize, IObjectPrototypeFactory } from 'influence-interfaces/object';
export var DeserializeUtility = new Utility({
  "implements": IDeserialize,
  name: 'admin-api',
  _determineObjectType: function _determineObjectType(obj) {
    return obj['_type'];
  },
  deserialize: function deserialize(item) {
    if (Array.isArray(item)) {
      // Item is an array so lets create one and iterate over the input
      var outp = [];

      for (var i = 0, imax = item.length; i < imax; i++) {
        outp.push(this.deserialize(item[i]));
      }

      return outp;
    } else if (item && _typeof(item) === "object") {
      var objectType = this._determineObjectType(item); // We traverse the object in case it contains objects further down
      // the hierarchy


      var _outp = {};
      var itemKeys = Object.keys(item);

      for (var _i = 0, _imax = itemKeys.length; _i < _imax; _i++) {
        var key = itemKeys[_i];
        _outp[key] = this.deserialize(item[key]);
      }

      if (objectType !== undefined) {
        // Ok so we got an object from . We need to create it with the prototype factory
        var ofu = new IObjectPrototypeFactory(objectType); // Create with the deserialized

        return ofu.getObject(_outp, this.roleManager);
      } // It wasn't an object defined here so we are just returning it as is


      return _outp;
    } else {
      // Ok, it is just an ordinary value, we just return it
      return item;
    }
  }
});