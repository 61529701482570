import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'
import { Animated } from 'inferno-animation'
import {
  Body,
  Button,
  Footer,
  Toolbar,
} from 'influence-ux-components'

import { AvatarImageFieldWidget } from 'app-field-AvatarImageField'

import { IApiClient, INotificationManager, ISessionManager } from 'influence-interfaces/presentation'
import { IUser } from 'app-entity-User'

import 'app-field-ImageField'
import 'app-field-AvatarImageField/index.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
const {
  INSTAGRAM_CLIENT_ID,
  INSTAGRAM_CALLBACK_URI
} = _env_

export default class Page extends Component {

  state = {
    loading: false 
  }

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/session`
    })
    
    return data
  }

  componentDidUpdate(prevProps, prevState) {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    if (safeGet(() => prevState.value._id !== currentUser._id)) {
      this.setState({
        value: currentUser
      })
    }
  }

  render (props, state, context) {
    return (
      <Animated className="Fullpage Onboarding" key="onboarding-4" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
        <img id="Page-Logo" src={logo_colored_svg} />
        {this.renderAvatar()}
        <div className="Onboarding-Content">
          <Body>
            <p>Vi avslutar med att koppla det Instagram konto som du vill visa bilder från i din profil. Om du inte valt en profilbild kommer vi hämta den också.</p>
            <p>Om du hoppar över det här steget kan du göra kopplingen senare.</p>
          </Body>
          <Footer>
            
            <Toolbar className="Onboarding-AcceptTerms"
              leading={(!this.state.loading && <Fragment>
                <Button
                className="Onboarding-Button Onboarding-Action-Button"
                onClick={this.doConnectInstagram} href="#instagram">Koppla Instagram!</Button>
                <i>or</i>
                <Button link onClick={this.doSkip}>Skippa</Button>
              </Fragment>)}>
              {this.state.loading && <h3>{this.state.loadingMessage}</h3>}
            </Toolbar>
          </Footer>
        </div>
      </Animated>
    )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    if (!currentUser) return null

    const propName = 'avatarImage'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      readOnly
      adapter={dummyAdapter}
      value={currentUser[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  doConnectInstagram = (e) => {
    e.preventDefault()
    try {
      this.setState({
        loading: true,
        loadingMessage: 'Redirecting to Instagram...'
      })

      // Show success indicator at center of screen
      new INotificationManager().showSuccessMessage()
      // https://developers.facebook.com/docs/instagram-basic-display-api/overview#permissions
      const scope = ['user_profile', 'user_media'].join(',')
      window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${INSTAGRAM_CALLBACK_URI}&scope=${scope}&response_type=code`
    }
    catch (e) {
      // TODO: Error handling
      return null
    }
  }

  doSkip = (e) => {
    e.preventDefault()
    this.context.router.history.push(`/admin/dashboard`)
  }
}

