import { createComponentVNode } from "inferno";
import classnames from 'classnames';
import StateButton from '../buttons/StateButton';
import ButtonBar from '../buttons/ButtonBar';
/**
 * 
 * @param {String} param0.className
 * @param {object[]} param0.options filter options list [{value, label}]
 * @param {String} param0.value current value
 * @param {function} param0.onChange aonChange callback
 */

export default function (_ref) {
  var className = _ref.className,
      options = _ref.options,
      value = _ref.value,
      onChange = _ref.onChange;
  return createComponentVNode(2, ButtonBar, {
    "className": classnames('Filter', className),
    children: options.map(function (item) {
      return createComponentVNode(2, StateButton, {
        "isSelected": item.value === value,
        "onClick": function onClick(e) {
          return onChange(item.value);
        },
        children: item.label
      });
    })
  });
}