import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'
import classnames from 'classnames'
import {
  Body,
  Button,
  Footer,
  Form,
  MessageCanvas,
  Section,
  Toolbar,
} from 'influence-ux-components'
import { FormRows } from 'influence-ux-formlib'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField'

import AdminPageTemplate from './AdminPageTemplate'

import 'app-field-ImageField'
import 'app-field-AvatarImageField/index.scss'

import { IUser } from 'app-entity-User'
import { IApiClient, INotificationManager, IPageManager, ISessionManager } from 'influence-interfaces/presentation'

import './DashboardPage.scss'
import Logo from '../../widgets/Logo'

const selectFields = ['firstName', 'lastName', 'title', 'description', 'instagramImages']
const omitFields = []

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const {
  FRONTEND_BASE_URI,
  INSTAGRAM_CLIENT_ID,
  INSTAGRAM_CALLBACK_URI
} = env

export default class DashboardPage extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: new ISessionManager({ registry: context.registry }).getCurrentUser() || {},
      validationError: undefined,
    }
  }

  static async fetchData ({registry, match, location, router}) {
    if (typeof window !== 'undefined' && window.innerWidth > 576) {
      // Check if we are in desktop mode, in which case redirect to dashboard
      if (location.pathname === '/admin') {
        router.history.replace('/admin/dashboard')
      }
    }

    new IPageManager({ registry }).setMetaData({
      title: 'Admin Kameleonten',
      url: FRONTEND_BASE_URI + location.pathname
    })

    const { data } = await new IApiClient({ registry }).query({
      URI: '/session'
    })

    if (!data) return undefined

    if (IUser.providedBy(data)) {
      // Check that user has accepted terms and conditions
      if (safeGet(() => data.termsAndConditions.name) != 'onboarding-terms') {
        debugger
        return safeGet(() => router.history.push('/register_2'))
      }
    }

    return data
  }

  componentDidUpdate(prevProps, prevState) {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    if (safeGet(() => prevState.value._id !== currentUser._id)) {
      this.setState({
        value: currentUser
      })
    }
  }
  
  render (props, state, context) {
    const { match } = this.props
    const { isExact, path } = match
    
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
    if (!currentUser) return null
    
    const cls = {
      "not-on-top": !isExact,
      "isAdminRoot": path == '/admin' // Mobile menu navigation
    }

    const userIsMember = currentUser.roles.indexOf('member') >= 0
    
    return (
      <AdminPageTemplate className={classnames("Dashboard", this.props.className, cls)}> 
        {userIsMember && this.renderProfileBody()}
        {!userIsMember && this.renderAdminBody()}
      </AdminPageTemplate>
    )
  }

  renderAdminBody () {
    return (
      <MessageCanvas>
        <h2>You are an admin user!</h2>
        <p>Only users with the role 'member' get the shortcut to edit their profile here.</p>
        <p>You can add that role to a user to show the profile edit form.</p>
      </MessageCanvas>
    )
  }

  renderProfileBody () {
    return (<Fragment>
      <Body>
        {this.renderAvatar()}
        <Section>
          <Form onSubmit={this.doSubmit} className='IEditItem'>
            <FormRows
              schema={IUser.schema}
              validationErrors={this.state.validationErrors}
              value={this.state.value}
              selectFields={selectFields}
              omitFields={omitFields}
              onChange={this.didUpdate} />
          </Form>
        </Section>
      </Body>
      <Footer fixed>
          {this.renderProfileToolbar()}
      </Footer>
    </Fragment>)
  }

  renderProfileToolbar () {
    const hasIgAccount = this.state.value.accounts.reduce((prev, curr) => prev || curr._type === 'AccountInstagram', false)
    return (
      <Toolbar
        leading={<Button onClick={this.doSave}>Update</Button>}
        trailing={hasIgAccount
          ? <Button link onClick={this.doUpdateIg}>Update Instagram Images</Button>
          : <Button primary onClick={this.doConnectIg}>Connect Instagram</Button>} />
    )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    if (!currentUser) return null

    const propName = 'avatarImage'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      adapter={dummyAdapter}
      value={currentUser[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  didUpdate = (propName, value) => {
    const newVal = this.state.value
    newVal[propName] = value

    let validationErrors
    if (this.state.submitted) {
      validationErrors = IUser.schema.validate(this.state.value, {
        selectFields,
        omitFields
      })
    }

    this.setState({
        value: newVal,
        validationErrors
    })
  }

  doSave = (e) => {
    e.preventDefault()

    let validationErrors = IUser.schema.validate(this.state.value, {
      selectFields,
      omitFields
    })
    if (validationErrors) {
      return this.setState({
        validationErrors,
        submitted: true
      })
    }
    
    new IApiClient().update({
        URI: `/admin/User/${new ISessionManager().getCurrentUser()._id}`,
        data: this.state.value,
        invalidate: ['/admin/User', '/session']
    }).then(async ({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        await new ISessionManager().refreshCurrentUser()
        new INotificationManager().showSuccessMessage()
    })
  }

  doConnectIg = (e) => {
    e.preventDefault()
    new INotificationManager().showSuccessMessage()
    const scope = ['user_profile', 'user_media'].join(',')
    window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${INSTAGRAM_CALLBACK_URI}&scope=${scope}&response_type=code`
  }

  doUpdateIg = (e) => {
    e.preventDefault()
    new IApiClient().update({
      URI: `/admin/User/${new ISessionManager().getCurrentUser()._id}/instagram`,
      invalidate: ['/admin/User', '/session']
    }).then(async ({data}) => {
        if (data.success) {
          await new ISessionManager().refreshCurrentUser()
          new INotificationManager().showSuccessMessage()
        }
        else {
          throw new Error('Failed updating instagram images')
        }
    })
  }
}