var Interface = require('component-registry').createInterfaceClass('influence-interfaces');

module.exports.IWorkflow = new Interface({
  /*
  The idea is to extend this object prototype when creating app specific workflows. Content objects that want
  to use a workflow in turn extends it
  */
  name: 'IWorkflow',
  workflows: "object -- collection of workflows"
});
module.exports.IWorkflowLookup = new Interface({
  /*
      This allows us to look up registered workflows
  */
  name: 'IWorkflowLookup'
});

module.exports.IWorkflowLookup.prototype.getInterface = function () {};

module.exports.IWorkflowState = new Interface({
  /*
  An adapter to interact with a workflow. This allows permission checks etc to be performed.
  */
  name: 'IWorkflowState'
}); // "function -- get the state of adapted workflow object",

module.exports.IWorkflowState.prototype.getState = function () {}; // "function -- set the state of adapted workflow object",


module.exports.IWorkflowState.prototype.setState = function () {}; // "function -- returns all defined workflow states for this workflow",


module.exports.IWorkflowState.prototype.getAllStates = function () {}; // "function -- returns available workflow states given permissions and current state",


module.exports.IWorkflowState.prototype.getAvailableStates = function () {};