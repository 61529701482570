import { Component } from 'inferno'
import { safeGet, safeJoin } from 'safe-utils'

import {
  ResponsiveImage
} from 'influence-ux-responsive-image'

import { getMobiledocRenderer } from 'influence-ux-mobiledoc'

import PublTemplate from './PublTemplate'

import { IApiClient, IAnalytics, IPageManager, ISessionManager } from 'influence-interfaces/presentation'

import './StaffPage.scss'
import mainImageJpg from '../../img/content/main_image.jpg'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class StaffPage extends Component {

    constructor(props) {
      super(props)
      this.renderer = getMobiledocRenderer()
    }

    static async fetchData ({registry, match, location}) {
      const { pageName } = match.params
      const { data } = await new IApiClient({ registry })
        .query([
          { URI: `/public/staff` },
          { URI: `/public/staff/${pageName}` },
          { URI: `/public/Page/en-trappa-ner` },
        ])

      const page = safeGet(() => data[1])
      new IPageManager({ registry }).setMetaData({
        title: `Kameleonten - ${safeGet(() => page.title)}`,
        description: safeGet(() => page.intro),
        url: FRONTEND_BASE_URI + location.pathname
      })

      return data
    }

    render ({ fetchData }, state, { router }) {
      const [staff, page, pageDownstairs] = fetchData || []
      const { params } = router.route.match

      // TODO: Läs in prislistan
      
      return (
          <PublTemplate
            key={params.id || 'start'}
            mainImgUrl={safeGet(() => page.headerImage) || mainImageJpg}
            pageDownstairs={pageDownstairs}
            staff={staff}>
              <article className="publStaffIntro">
                <ResponsiveImage className="publAvatar" image={safeGet(() => page.avatarImage)} />
                <h1>{page && safeJoin([page.firstName, page.lastName], ' ')}</h1>
                <h2>{safeGet(() => page.title)}</h2>
                <pre>{safeGet(() => page.description)}</pre>
              </article>
              <section className="publInstaGrid">
                {this.renderImages(page)}
              </section>
            </PublTemplate>
      )
    }

    renderImages (user) {
      if (user && Array.isArray(user.instagramImages)) {
        return user.instagramImages.map((img) => <div className="image-container"><ResponsiveImage image={img} /></div>)
      }
      else {
        return null
      }
    }
}
