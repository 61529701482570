import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createVNode, createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import { Link } from 'inferno-router';
import classnames from 'classnames';
/**
 * Inferno router nav button
 * @param {String} param0.className 
 * @param {bool} param0.primary use primary color scheme 
 * @param {bool} param0.secondary use secondary color scheme
 * @param {bool} param0.link use link color scheme (default)
 * @param {bool} param0.to nav path
 * @param {String} param0.children button label
 * @param {Function} param0.onClick onClick callback
 * 
 */

export default function (_ref, context) {
  var className = _ref.className,
      primary = _ref.primary,
      secondary = _ref.secondary,
      link = _ref.link,
      to = _ref.to,
      onClick = _ref.onClick,
      children = _ref.children;
  var typeCls = primary && 'primary' || secondary && 'secondary' || link && 'link' || 'link'; // default

  var props = {
    className: classnames('Button', typeCls, className),
    to: to,
    onClick: onClick
  };

  if (context.router !== undefined && onClick === undefined) {
    return normalizeProps(createComponentVNode(2, Link, _objectSpread(_objectSpread({}, props), {}, {
      children: children
    })));
  } else {
    return normalizeProps(createVNode(1, "a", null, children, 0, _objectSpread({
      "href": to
    }, props)));
  }
}