import _objectSpread from "/var/www/app-salongkameleonten-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createComponentVNode, normalizeProps } from "inferno";
import { Adapter, Utility } from 'component-registry';
import { Modal } from 'influence-ux-components';
import { PublicForm } from './widgetPublic';
import { ILightbox } from 'influence-interfaces/presentation';
import { IMediaCard } from './interface';

function Lightbox(props) {
  return createComponentVNode(2, Modal, {
    "fade": true,
    "isOpen": props.isOpen,
    "doClose": props.doClose,
    children: normalizeProps(createComponentVNode(2, PublicForm, _objectSpread({}, props)))
  });
}

new Adapter({
  implements: ILightbox,
  adapts: IMediaCard,
  Component: Lightbox
});
new Utility({
  implements: ILightbox,
  name: 'MediaCard',
  Component: Lightbox
});