import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/lib/permissions';
import { PublishWorkflow } from 'app-workflow-PublishWorkflow';
import { TimedPublishing } from 'app-aspect-TimedPublishing';
import { IPage } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin', 'customerService'],
  // TODO: Anon should not be alowed to create image
  read: ['owner', 'admin', 'customerService', 'anonymous:publishWorkflow.published'],
  update: ['owner', 'admin', 'customerService'],
  delete: ['owner', 'admin', 'customerService']
};
export var Page = createObjectPrototype({
  implements: [IPage],
  extends: [Permissions, PublishWorkflow, TimedPublishing],
  constructor: function constructor(params) {
    this._type = 'Page';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });

    this._ITimedPublishing.constructor.call(this, params);
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Page',
  getPrototype: function getPrototype() {
    return Page;
  },
  getObject: function getObject(data) {
    data = data || {};
    return new Page(data);
  }
});