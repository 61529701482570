import { createVNode } from "inferno";
export default function (_ref) {
  var className = _ref.className;
  return createVNode(32, "svg", className, createVNode(32, "g", null, createVNode(32, "polygon", null, null, 1, {
    "points": "14.2991597 28.188 20.9546218 20.2468235 27.5193277 28.188 30 28.188 22.194958 18.9611092 29.5764706 10.188 27.2773109 10.188 20.9546218 17.7258151 14.8436975 10.188 12.4235294 10.188 19.7445378 18.9106891 12 28.188"
  }), 2, {
    "id": "icon",
    "transform": "translate(-12.000000, -10.000000)",
    "stroke": "none",
    "fill": "currentFill",
    "fill-rule": "nonzero"
  }), 2, {
    "width": "18px",
    "height": "19px",
    "viewBox": "0 0 18 19"
  });
}