import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, BoolField, TextField, TextAreaField, SelectField, ListField, ObjectField, i18n, DateField } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField/field';
import { ImageListField } from 'app-field-ImageListField/field';
import { IAccountEmail } from 'app-entity-AccountEmail/interface';
var IUser = new Interface({
  name: 'IUser',
  schema: new Schema('User Schema', {
    avatarImage: new ImageField({
      label: i18n('IUser-avatarImage-label', 'Profile Image'),
      placeholder: i18n('IUser-avatarImage-placeholder', 'Drag and drop...'),
      help: i18n('IUser-avatarImage-help', 'Something reasonable please')
    }),
    firstName: new TextField({
      label: i18n('IUser-firstName-label', 'First Name'),
      required: true
    }),
    lastName: new TextField({
      label: i18n('IUser-lastName-label', 'Last Name'),
      required: true
    }),
    title: new TextField({
      label: i18n('IUser-title-label', 'Titel'),
      required: true
    }),
    description: new TextAreaField({
      label: i18n('IUser-description-label', 'Kort beskrivning')
    }),
    accounts: new ListField({
      label: i18n('IUser-accounts-label', 'Kopplade Konton'),
      valueType: new ObjectField({
        label: i18n('IUser-accountsEmail-label', 'E-mail account'),
        objectFactoryName: 'AccountEmail',
        interface: IAccountEmail
      }),
      required: true
    }),
    roles: new ListField({
      label: i18n('IUser-roles-label', 'Systemroller'),
      valueType: new SelectField({
        options: [{
          name: 'admin',
          title: i18n('IUser-roles-option-admin', 'Admin')
        }, // { name: 'customerService', title: i18n('IUser-roles-option-customerService', 'Community Manager')},
        {
          name: 'member',
          title: i18n('IUser-roles-option-member', 'Member')
        }],
        valueType: new TextField({})
      }),
      minItems: 1,
      required: true
    }),
    instagramImages: new ImageListField({
      label: i18n('IUser-instagramImages-label', 'Instagram Images'),
      readOnly: true
    })
  })
});
export { IUser };