import { createVNode } from "inferno";
import classnames from 'classnames';
/**
 * Basic button
 * @param {String} param0.className 
 * 
 */

export default function (_ref) {
  var className = _ref.className,
      inline = _ref.inline,
      children = _ref.children;
  return createVNode(1, "div", classnames('ButtonBar', {
    inline: inline
  }, className), children, 0);
}