import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, TextField, TextAreaField, DateTimeField, i18n } from 'isomorphic-schema';
import { MobiledocField } from 'app-field-MobiledocField/field';
import { ImageField } from 'app-field-ImageField/field';
export var IPage = new Interface({
  name: 'IPage',
  schema: new Schema('Page Schema', {
    title: new TextField({
      label: i18n('IPage-title-label', 'Title'),
      placeholder: i18n('IPage-title-placeholder', 'Type here...'),
      require: true
    }),
    headerImage: new ImageField({
      label: i18n('IPage-headerImage-label', 'Header image'),
      help: i18n('IPage-headerImage-help', 'If you leave this empty, the default image will be used')
    }),
    publishingStartsAt: new DateTimeField({
      label: i18n('IPage-publishingStartsAt-label', 'Publish from')
    }),
    publishingEndsAt: new DateTimeField({
      label: i18n('IPage-publishingEndsAt-label', 'Publish to')
    }),
    intro: new TextAreaField({
      label: i18n('IPage-intro-label', 'Intro'),
      placeholder: i18n('IPage-intro-placeholder', 'Type here...'),
      require: true
    }),
    body: new MobiledocField({
      label: i18n('IPage-body-label', 'Body'),
      placeholder: i18n('IPage-body-placeholder', 'Type here...')
    })
  })
});