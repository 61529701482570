import { Component } from 'inferno'
import { Page } from 'influence-ux-components'
import { typeMapping } from './_typeMapping'

import { IApiClient, IEditForm, IPageManager } from 'influence-interfaces/presentation'
import './CreateAndEditPage.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class EditPage extends Component {

    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'Redigera...',
        url: FRONTEND_BASE_URI + location.pathname
      })
  
      const objType = typeMapping[match.params.type]
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/${objType}/${match.params.id}`
      })

      return data
    }

    render () {
        const data = this.props.fetchData
        if (!data) return null

        const EditForm = new IEditForm(data).Component
        return (
          <Page key="edit"
            className="EditPage"
            hasStickyHeader
            hasStickyFooter
            animationPrefix="PageNav">
            <EditForm value={data} {...this.props} />
          </Page>
        )
    }
}

