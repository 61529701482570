import { createVNode, createComponentVNode } from "inferno";
import { forwardRef } from 'inferno';
import classnames from 'classnames';
import { Animated } from 'inferno-animation';
export default forwardRef(function (_ref, ref) {
  var className = _ref.className,
      animationPrefix = _ref.animationPrefix,
      hasStickyHeader = _ref.hasStickyHeader,
      hasStickyFooter = _ref.hasStickyFooter,
      children = _ref.children;
  className = classnames('tab-page', {
    hasStickyHeader: hasStickyHeader,
    hasStickyFooter: hasStickyFooter
  }, className);

  if (animationPrefix) {
    return createComponentVNode(2, Animated, {
      "className": className,
      "prefix": animationPrefix,
      children: children
    }, null, ref);
  }

  return createVNode(1, "div", className, children, 0, null, null, ref);
});