import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-workflow');
import { i18n } from 'isomorphic-schema';
/**
 * IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT
 * 
 * When adding a workflow you also need to add the corresponding property to the
 * Elasticsearch index and migrate the database!!!
 * 
 * Make sure you update the definition in 
 *  - cli/helpers/elasticsearch
 *  - packages/AdminApi/README.md
 * 
 * Basic workflows:
   "workflows": {
			"properties": {
				"publishWorkflow": { "type": "keyword" },
				"userWorkflow": { "type": "keyword" },
				"invitationWorkflow": { "type": "keyword" }
			}
    }
 *
 * IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT
 */

export var IInvitationWorkflow = new Interface({
  // Interface for InvitationWorkflow
  name: 'IInvitationWorkflow',
  schema: {
    statePropName: 'invitationWorkflow',
    workflowStates: {
      draft: {
        title: i18n('IInvitationWorkflow-option-draft', 'Draft')
      },
      pending: {
        title: i18n('IInvitationWorkflow-option-pending', 'Pending')
      },
      activated: {
        title: i18n('IInvitationWorkflow-option-activated', 'Activated')
      },
      trash: {
        title: i18n('IInvitationWorkflow-option-trash', 'Trash')
      }
    }
  }
});