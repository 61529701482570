import { Component } from 'inferno'
import querystring from 'querystring'
import {
  Body,
  Trailing,
} from 'influence-ux-components'

import AdminPageTemplate from './AdminPageTemplate'

import { IApiClient, IListItem, IPageManager } from 'influence-interfaces/presentation'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'

import './ListPage.scss'
import 'app-entity-Page/index.scss'

import '../../formlib/RichtextToolbar'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

const pagePaths = [
  'start',
  'boka-tid',
  'priser',
  'tape-extensions',
  'om-salongen',
  'en-trappa-ner',
  'arbeta-hos-oss',
  'allmanna-villkor',
]

const snippetPaths = [
//  'receiptFinePrint',
//  'productInfo',
]

export default class PagesAndSnippets extends Component {

  static limit = 30

  constructor (props) {
    super(props)

    this.state = {
      pages: props.fetchData || [],
      queryIsLoading: false
    }
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      description: `Fixed pages and snippets.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    const pageSlots = [...pagePaths, ...snippetPaths]
    try {
      const { data } = await new IApiClient({ registry }).query(
        pageSlots.map((id) => ({
          URI: `/content/Page/${id}`
        })
      ))
      
      return data
    }
    catch (e) {
      return []
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, invitationWorkflow, userWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, invitationWorkflow, userWorkflow }
    const pages = nextProps.fetchData || []
    this.setState({ pages, ...query })
  }

  doCreate = async (pathId) => {
      const newPage = new IObjectPrototypeFactory('Page').getObject({ _pathId: pathId })

      // Create an order
      const { data } = await new IApiClient().create({
        URI: '/content/Page',
        data: newPage,
        invalidate: '/content/Page'
      })

      this.context.router.history.push(`/admin/page/${data._pathId}`)
  }

  _getPage = (pathId) => {
    return this.state.pages.reduce((curr, next) => curr || (next && next._pathId === pathId ? next : undefined), undefined)
  }

  render () {
    // Pass type to <Page> as either the param type, or MediaCard if on Media endpoint, or default to Page
    const type = 'page'

    return (
      <AdminPageTemplate
        className={'ListPage-' + type}>
        <Body ref={this._scrollRef}>
          <h2>Pages</h2>
          {pagePaths.map((path) => <PageSlot obj={this._getPage(path)} pathId={path} doCreate={this.doCreate} />)}
          <h2>Snippets</h2>
          {snippetPaths.map((path) => <PageSlot obj={this._getPage(path)} pathId={path} doCreate={this.doCreate} />)}
        </Body>
      </AdminPageTemplate>
    )
  }
}

function PageSlot({obj, pathId, doCreate}) {
  if (obj === undefined) {
    return (
      <div className="ListItem">
        <Body>
          <a href={`/content/page/create?pathId=${pathId}`} onClick={(e) => {e.preventDefault(); doCreate(pathId)}}>{pathId}</a>
          <h3>Click the link to create this page</h3>
        </Body>
        <Trailing className="workflow">
          <h2 className="status">
            TO BE CREATED
          </h2>
        </Trailing>
      </div>
    )
  }
  else {
    const ListItem = new IListItem(obj).Component
    return <ListItem key={obj._id} context={obj} />
  }
}
