import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, createVNode(32, "g", null, [createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "0",
    "y": "0",
    "width": "95",
    "height": "150"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "105",
    "y": "0",
    "width": "70",
    "height": "70"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "105",
    "y": "80",
    "width": "70",
    "height": "70"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "185",
    "y": "0",
    "width": "70",
    "height": "70"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "185",
    "y": "80",
    "width": "70",
    "height": "70"
  })], 4, {
    "id": "icon_gallery_one_four_horizontal",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  }), 2, {
    "width": "255px",
    "height": "150px",
    "viewBox": "0 0 255 150"
  });
}