import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, BoolField, ObjectField, PasswordField, TextField, TextAreaField, DateTimeField, i18n } from 'isomorphic-schema'; // Instagram auth docs
// https://www.instagram.com/developer/authentication/
// Scopes: https://www.instagram.com/developer/authorization/
// We probablt need these scopes: basic, public_content, comments, relationships, likes, follower_list

var instagramUserSchema = new Schema('Instagram User Schema', {
  id: new TextField({}),
  username: new TextField({}),
  fullName: new TextField({}),
  profilePicture: new TextField({}),
  // Profile picture URL
  bio: new TextAreaField({}),
  webpage: new TextField({})
});
var IAccountInstagram = new Interface({
  name: 'IAccountInstagram',
  schema: new Schema('AccountInstagram Schema', {
    useForLogin: new BoolField({
      label: i18n('IAccountInstagram-useForLogin-label', 'Use for login')
    }),
    accessToken: new PasswordField({
      label: i18n('IAccountInstagram-accessToken-label', 'Access Token')
    }),
    accessTokenExpiresAt: new DateTimeField({}),
    user: new ObjectField({
      label: i18n('IAccountInstagram-user-label', 'Basic User Data'),
      schema: instagramUserSchema
    }),
    userId: new TextField({
      label: i18n('IAccountInstagram-userId-label', 'Instagram User ID')
    })
  })
});
export { IAccountInstagram };