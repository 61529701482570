import { Adapter } from 'component-registry'
import { safeJoin, safeGet } from 'safe-utils'
import { Thumbnail } from 'influence-ux-responsive-image'
import {
  NavButton,
} from 'influence-ux-components'

import { IUser } from 'app-entity-User'
import { IPublMenuItem } from './interface'

import './MenuItem.scss'

function PublMenuItem ({ context }) {
  return (
    <NavButton to={`/frisor/${context._pathId}/${context._slug || ''}`} className="publMenuItem">
      <Thumbnail className="avatar" image={context.avatarImage} />
      <h2>{safeJoin([context.firstName, context.lastName], ' ')}</h2>
      <h3>{safeGet(() => context.title)}</h3>
    </NavButton>
  )
}

new Adapter({
  implements: IPublMenuItem,
  adapts: IUser,
  Component: PublMenuItem
})