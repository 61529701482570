import { Component } from 'inferno'
import classnames from 'classnames'
import { findDOMNode } from 'inferno-extras'
import { safeGet } from 'safe-utils'
import {
  Body,
  Footer,
  Section
} from 'influence-ux-components'
import StickyRow from 'app-widget-StickyRow'
import { AdminMenu } from './AdminMenu'

import { ISessionManager } from 'influence-interfaces/presentation'

import './AdminPageTemplate.scss'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
let { IMAGE_URI_BASE } = _env_
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:3011/images'

export default class AdminPageTemplate extends Component {

  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    requestAnimationFrame(this._checkAdminMenuHeight)
  }

  _checkAdminMenuHeight = () => {
    // Adding 4px for shadow
    const tmp = safeGet(() => document.querySelector('.AdminMenu').clientHeight, 0) + 4
    if (tmp !== this.state.mainMenuHeight) {
      this.setState({
        mainMenuHeight: tmp
      })
    }
    requestAnimationFrame(this._checkAdminMenuHeight)
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (!nextContext.router.route.match.isExact && this.context.router.route.match.isExact) {
      // We are no longer on top so store scroll position and constrain content to screen to remove scrollbar
      this._storedScroll = this._storedScroll || {}
      const x = this._storedScroll['x'] = typeof window !== 'undefined' ? window.scrollX : 0
      const y = this._storedScroll['y'] = typeof window !== 'undefined' ? window.scrollY : 0
      requestAnimationFrame(() => {
        if (this && !this.$UN) {
          safeGet(() => findDOMNode(this).scroll(x, y))
        }
      })
    }
    else if (nextContext.router.route.match.isExact && !this.context.router.route.match.isExact) {
      // We are now on top again. Restore scroll position and let the content flow
      if (typeof window !== 'undefined') {
        const { x, y } = this._storedScroll || { x: 0, y: 0 }
        // Need to move after re-render so we have space to scroll
        requestAnimationFrame(() => {
          if (this && !this.$UN) {
            safeGet(() => findDOMNode(this).scroll(0, 0))
            window.scroll(x, y)
          }
        })
      }
    }
  }

  renderToolbar () {
    if (!this.props.toolbar) {
      return null
    }
    else {
      return (
        <div className="toolbar-container">{this.props.toolbar}</div>
      )
    }
  }

  renderActionbar () {
    if (!this.props.actionbar) {
      return null
    }
    else {
      return (
        <Footer fixed className="actionbar-container">{this.props.actionbar}</Footer>
      )
    }
  }

  renderFilter () {
    if (!this.props.filter) return null

    return (
      <Section fixed className="filter-container">
        {this.props.filter}
      </Section>
    )
  }

  render (props, state, context) {
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
    if (!currentUser) return null
    
    const { isExact } = context.router.route.match
    const cls = { "not-on-top": !isExact }

    return (
      <div className={classnames("AdminPageTemplate", cls, props.className)}>
        <AdminMenu active={true} />
        {this.renderToolbar()}
        {this.renderFilter()}
        {this.props.children || null}
        {this.renderActionbar()}
      </div>
    )
  }
}



