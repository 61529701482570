var Interface = require('component-registry').createInterfaceClass('influence-interfaces');

module.exports.IPermissions = new Interface({
  /*
      Baseclass for access permissions
  */
  name: 'IPermissions',
  permissions: "object { owners, create, read, update, delete }"
});
module.exports.IRoleManager = new Interface({
  /*
  This is a base obejct for RoleManager implementatation
  */
  name: 'IRoleManager'
});
module.exports.IHasRoleManager = new Interface({
  /*
  MArker interface to show that the permissions are set by passing a role manager
  */
  name: 'IRoleManager'
});
module.exports.IRoleManagerAdapter = new Interface({
  /*
  Common methods to access a role manager
  */
  name: 'IRoleManagerAdapter'
}); // "function () : { create, read, update, delete }",

module.exports.IRoleManagerAdapter.prototype.getPermissions = function (type) {}; // "function (principalId) : [role, ...]",


module.exports.IRoleManagerAdapter.prototype.getRolesByPrincipalId = function (principalId) {};
/**
 * TODO: Consider if this should be removed!
 */


module.exports.IUXPermissions = new Interface({
  /*
  This named new Utility({ is used to check permissions in UX.
  
  Implemented so it can be used like this:
   if (ip.user(currentUser).may('view').this(obj)) {
      // Then do stuff
  }
  */
  name: 'IUXPermissions'
}); // "function (name, permissions) -- use this to check that the permissions you use for this instance exists",

module.exports.IUXPermissions.prototype.permissionsUsed = function (name, permissions) {}; // function (currentUser) -- returns a permission lookup object function (permissionName) that looks up the permission function (obj) and allows you to optionally apply that on your content object


module.exports.IUXPermissions.prototype.user = function (currentUser) {};