import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createVNode, createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import classnames from 'classnames';
import IconX from '../icons/icon_x';
/**
 * Close button for modals
 * @param {String} param0.className 
 * @param {String} param0.children button label
 * @param {Function} param0.onClick onClick callback
 * 
 */

export default function (_ref) {
  var className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick;
  var props = {
    className: classnames('ButtonClose', className),
    onClick: onClick
  };
  return normalizeProps(createVNode(1, "button", null, [createComponentVNode(2, IconX, {
    "className": "icon"
  }), children], 0, _objectSpread({}, props)));
}