import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, BoolField, TextField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField/field';
import { ImageListField } from 'app-field-ImageListField/field';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField/field';
var IMediaCard = new Interface({
  name: 'IMediaCard',
  schema: new Schema('MediaCard Schema', {
    shortDescription: new TextField({
      label: i18n('IMediaCard-shortDescription-label', 'Caption')
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    }),
    cssClassName: new TextField({
      label: i18n('IMediaCard-cssClassName-label', 'CSS Class Name')
    })
  })
});
export { IMediaCard };