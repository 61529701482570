import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { BaseField } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IImageField = new Interface({
  name: 'IImageField'
});
export var ImageField = createObjectPrototype({
  implements: [IImageField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  }
});