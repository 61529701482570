import { globalRegistry, createObjectPrototype } from 'component-registry';
import { ITimedPublishing } from './interface';
export var TimedPublishing = createObjectPrototype({
  implements: [ITimedPublishing],
  constructor: function constructor(params) {
    if (params.publishingStartsAt) {
      this.publishingStartsAt = new Date(params.publishingStartsAt);
      delete params.publishingStartsAt;
    }

    if (params.publishingEndsAt) {
      this.publishingEndsAt = new Date(params.publishingEndsAt);
      delete params.publishingEndsAt;
    }
  }
});