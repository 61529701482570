var Interface = require('component-registry').createInterfaceClass('influence-interfaces');

export var ITranslationUtil = new Interface({
  name: 'ITranslationUtil'
});
/**
 * Utility to translate label to selected language.
 * @param {String} label - i18n label 
 * @param {String} lang - i18n language (en, sv, de...)
 * @param {String fallback - fallback text if no translation is found
 */

ITranslationUtil.prototype.message = function (label, lang, fallback) {};

export var ITranslationLang = new Interface({
  name: 'ITranslationLang'
});
/**
 * Utility to get the current language.
 * @return {String} i18n language (en, sv, de...)
 */

ITranslationLang.prototype.lang = function () {};