'use strict';
/*

    To use this input widget adapter you need to register it with your
    adapter registry.

*/

import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

import { Component } from 'inferno';
import { Adapter } from 'component-registry';
import { interfaces } from 'isomorphic-schema';
import { IInputFieldWidget } from '../interfaces';
import { Input } from 'influence-ux-components';
import { renderString } from './common';
import { generateId } from './utils'; // Placeholder

function CheckboxWidget(_ref) {
  var inputName = _ref.inputName,
      namespace = _ref.namespace,
      options = _ref.options,
      doesNotRenderLabel = _ref.doesNotRenderLabel,
      id = _ref.id,
      propName = _ref.propName,
      value = _ref.value,
      _onChange = _ref.onChange;
  var field = this.props.adapter.context;
  var isValid = this.props.validationError || this.props.invariantError ? false : undefined;
  var ariaLabels = {
    'aria-invalid': isValid !== undefined,
    'aria-labelledby': doesNotRenderLabel ? undefined : id,
    'aria-label': doesNotRenderLabel ? renderString(field.label || 'influence-ux-formlib--InputField', options && options.lang, 'Bool Field') : undefined,
    'aria-required': field._isRequired ? field._isRequired : undefined
  };
  return normalizeProps(createComponentVNode(2, Input, _objectSpread(_objectSpread({
    "type": "checkbox",
    "id": generateId(namespace, '__Field'),
    "name": inputName
  }, ariaLabels), {}, {
    "valid": isValid,
    "placeholder": renderString(field.placeholder, options && options.lang),
    "readOnly": field.readOnly,
    "value": value,
    "onChange": function onChange(e) {
      _onChange(propName, e.target.checked);
    }
  })));
}

export default CheckboxWidget;
new Adapter({
  "implements": IInputFieldWidget,
  adapts: interfaces.IBoolField,
  Component: CheckboxWidget
});