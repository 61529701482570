import { createObjectPrototype, Utility } from 'component-registry';
import { IAccount } from 'app-entity-Account/interface';
import { IAccountEmail } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
export var AccountEmail = createObjectPrototype({
  implements: [IAccountEmail, IAccount],
  constructor: function constructor(params) {
    this._type = 'AccountEmail';
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'AccountEmail',
  getPrototype: function getPrototype() {
    return AccountEmail;
  },
  getObject: function getObject(data) {
    return new AccountEmail(data);
  }
});