import { Utility } from 'component-registry'
import { ITranslationUtil, ITranslationLang } from 'influence-interfaces/i18n'
import { en } from './en'

const langLibrary = { en }

new Utility({
  implements: ITranslationUtil,
  message (label, lang, fallback) {
    // Use 'en' as fallback language
    const langDict = langLibrary[lang] || langLibrary['en']
    // Use label as fallback string
    return langDict[label] || fallback || label
  }
})

new Utility({
  implements: ITranslationLang,
  lang () {
    return 'en'
  }
})
