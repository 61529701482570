import { Component } from 'inferno'
import { safeGet, safeJoin } from 'safe-utils'
import { Animated } from 'inferno-animation'

import { AvatarImageFieldWidget } from 'app-field-AvatarImageField'

import { IApiClient, IPublicForm, IPageManager, ISessionManager } from 'influence-interfaces/presentation'
import { IUser } from 'app-entity-User'

import './RegisterStep.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

export default class Page extends Component {

 static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
      URI: `/admin/Invitation/${match.params.id}`
    })

    if (!data) return

    const name = safeJoin([data.firstName, data.lastName], ' ')
    new IPageManager({ registry }).setMetaData({
      title: 'Registrera konto hos Salong Kameleonten',
      description: `Hej${name ? ' ' + name : ''}! Nu ska du skapa din profil på sidan. Du använder ditt Facebook konto för att slippa komma ihåg några lösenord.`,
      url: 'https://salongkameleonten.se' + location.pathname
    })
    
    return data
  }

  render(props) {
    const data = this.props.fetchData

    if (!data) {
      return null
    }

    const Content = new IPublicForm(data).Component

    return (
      <Animated className="Fullpage Onboarding" key="onboard-1" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
        <img id="Page-Logo" src={logo_colored_svg} />
        {this.renderAvatar()}
        <div className="Onboarding-Content">
          <Content context={data} />
        </div>
      </Animated>
    )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    const propName = 'avatarImage'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      readOnly
      adapter={dummyAdapter}
      value={safeGet(() => currentUser[propName])}
      propName={propName}
      onChange={this.didUpdate} />
  }
}

