import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { TextField, i18n } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var ISearchField = new Interface({
  name: 'ISearchField'
});

ISearchField.prototype.validate = function () {};

ISearchField.prototype.fromString = function () {};

ISearchField.prototype.toFormattedString = function () {};

export var SearchField = createObjectPrototype({
  implements: [ISearchField],
  extends: [TextField]
});