import { Component } from 'inferno'
import { safeGet } from 'safe-utils'

import { getMobiledocRenderer } from 'influence-ux-mobiledoc'

import PublTemplate from './PublTemplate'

import { IApiClient, IAnalytics, IPageManager, ISessionManager } from 'influence-interfaces/presentation'

import './StartPage.scss'
import mainImageJpg from '../../img/content/main_image.jpg'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class StartPage extends Component {

    constructor(props) {
      super(props)
      this.renderer = getMobiledocRenderer()
    }

    static async fetchData ({registry, match, location}) {
      const pageSlug = match.params.id

      const { data } = await new IApiClient({ registry })
        .query([
          { URI: `/public/staff` },
          { URI: `/public/Page/${pageSlug || 'start'}` },
          { URI: `/public/Page/en-trappa-ner` },
        ])

      const page = safeGet(() => data[1])
      new IPageManager({ registry }).setMetaData({
        title: 'Kameleonten - Salongen med det extra lyxiga!',
        description: safeGet(() => page.intro),
        url: FRONTEND_BASE_URI + location.pathname
      })

      return data
    }

    render ({ fetchData }, state, { router }) {
      const [staff, page, pageDownstairs] = fetchData || []
      const { params } = router.route.match
      const { pathname } = router.route.location
      
      return (
          <PublTemplate
            key={pathname}
            mainImgUrl={safeGet(() => page.headerImage) || mainImageJpg}
            page={page}
            pageDownstairs={pageDownstairs}
            staff={staff}>
              <article className="publIntro">
                <h1>{safeGet(() => page.title)}</h1>
                <p>{safeGet(() => page.intro)}</p>
              </article>
              {this.renderer.render(safeGet(() => page.body))}
            </PublTemplate>
      )
    }
}
