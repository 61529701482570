import { Component } from 'inferno'
import { Page } from 'influence-ux-components'
import { typeMapping } from './_typeMapping'

import { ICreateForm, IPageManager } from 'influence-interfaces/presentation'

import './CreateAndEditPage.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class CreatePage extends Component {

    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'Skapa ny...',
        url: FRONTEND_BASE_URI + location.pathname
      })
  
      // Also  triggering update of RoleManager
      return
    }

    render (props, state, context) {
      const objType = typeMapping[context.router.route.match.params.type]
      if (!objType) return null
      
      const EditForm = new ICreateForm(objType).Component
      return (
        <Page key="create"
          className="CreatePage"
          hasStickyHeader
          hasStickyFooter
          animationPrefix="PageNav">
          <EditForm {...props} />
        </Page>
      )
    }
}
