import { createVNode, createComponentVNode, createTextVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Link } from 'inferno-router';
import { Body, Trailing } from 'influence-ux-components';
import { WorkflowActions } from 'app-widget-WorkflowWidgets';
import { safeJoin } from 'safe-utils';
import { IApiClient, IListItem, INotificationManager } from 'influence-interfaces/presentation';
import { IServiceProduct } from '../interface';
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow';
import './ListItem.scss';
var workflowStates = IPublishWorkflow.schema.workflowStates;
var workflowName = IPublishWorkflow.schema.statePropName;

function doChangeWorkflowState(obj, newState) {
  obj._workflows[workflowName] = newState;
  new IApiClient().update({
    URI: "/content/".concat(obj._type, "/").concat(obj._id),
    data: obj,
    invalidate: "/content/".concat(obj._type)
  }).then(function (_ref) {
    var data = _ref.data;
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage();
  });
}

function ListItem(_ref2, context) {
  var obj = _ref2.context;
  var targetPath = context.router.route.location.pathname + '/' + obj._pathId;
  return createVNode(1, "div", "ListItem", [createComponentVNode(2, Body, {
    children: [createComponentVNode(2, Link, {
      "to": targetPath,
      children: obj.title || '/' + obj._pathId
    }), createVNode(1, "h3", null, [createTextVNode("Pris: "), obj.fromPrice ? 'från ' : '', obj.priceSEK, createTextVNode(" kr")], 0)]
  }), createComponentVNode(2, Trailing, {
    "className": "workflow",
    children: [createVNode(1, "h2", "status", obj._workflows && obj._workflows[workflowName], 0), createComponentVNode(2, WorkflowActions, {
      "obj": obj,
      "propName": workflowName,
      "workflowStates": workflowStates,
      "doChangeState": function doChangeState(state) {
        return doChangeWorkflowState(obj, state);
      }
    })]
  })], 4);
}

new Adapter({
  implements: IListItem,
  adapts: IServiceProduct,
  Component: ListItem
});