import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('inferno-formlib');
/*

    Form generation components

*/

export var IInputFieldWidget = new Interface({
  // Render the objects schema as a form
  name: 'IInputFieldWidget'
}); // Inferno Component

IInputFieldWidget.prototype.Component = 'object';
export var IFormRowWidget = new Interface({
  // Render the objects schema as a form
  name: 'IFormRowWidget'
}); // Inferno Component

IFormRowWidget.prototype.Component = 'object';
export var IListRowContainerWidget = new Interface({
  // Render the objects schema as a form
  name: 'IListRowContainerWidget'
}); // Inferno Component

IListRowContainerWidget.prototype.Component = 'object';
export var IFileUploadUtil = new Interface({
  name: 'IFileUploadUtil'
}); // Upload file to blob storage

IFileUploadUtil.prototype.upload = function (file, progress, done) {}; // Delete file from blob storage 
// TODO: Is this the right way?


IFileUploadUtil.prototype["delete"] = function (uri) {};

export var IDraggableController = new Interface({
  // This utility is looked up by name. The name is passed by
  // the dropping actor
  name: 'IDraggableController'
}); // Create an object based on dragged data

IDraggableController.prototype.getObject = function (data) {}; // Check if we can drop source on target


IDraggableController.prototype.mayDrop = function (source, target) {};