import { createVNode, createFragment, createComponentVNode } from "inferno";
import { Fragment } from 'inferno';
import { Body, Button, ButtonBar, Footer, Header, Modal } from 'influence-ux-components';
import './index.scss';
export function ModalCloseWarning(_ref) {
  var isOpen = _ref.isOpen,
      doSaveClose = _ref.doSaveClose,
      doCancel = _ref.doCancel,
      doClose = _ref.doClose;
  return createComponentVNode(2, Modal, {
    "fade": true,
    "className": "ModalCloseWarning",
    "isOpen": isOpen,
    "doClose": doCancel,
    children: [createComponentVNode(2, Header, {
      children: "WARNING! Unsaved changes"
    }), createComponentVNode(2, Body, {
      children: createVNode(1, "p", null, "You have unsaved changes, are you sure you want to close without saving?", 16)
    }), createComponentVNode(2, Footer, {
      children: [createComponentVNode(2, ButtonBar, {
        "className": "leading",
        children: doSaveClose && doCancel ? createFragment([createComponentVNode(2, Button, {
          "primary": true,
          "onClick": doSaveClose,
          children: "Save & close"
        }), createVNode(1, "i", null, "or", 16), createComponentVNode(2, Button, {
          "link": true,
          "onClick": doCancel,
          children: "Cancel"
        })], 4) : doSaveClose && createComponentVNode(2, Button, {
          "primary": true,
          "onClick": doSaveClose,
          children: "Save & close"
        }) || doCancel && createComponentVNode(2, Button, {
          "primary": true,
          "onClick": doCancel,
          children: "Cancel"
        })
      }), doClose && createComponentVNode(2, ButtonBar, {
        "className": "trailing",
        children: createComponentVNode(2, Button, {
          "link": true,
          "onClick": function onClick(e) {
            doClose({
              event: e,
              force: true
            });
          },
          children: "Close"
        })
      })]
    })]
  });
}