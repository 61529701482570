import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import _extends from "@babel/runtime/helpers/extends";
import { createVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

export default function MultiImageIcon(_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));

  return normalizeProps(createVNode(32, "svg", null, [createVNode(32, "defs", null, createVNode(32, "path", null, null, 1, {
    "d": "M159.786326,43.027615 C161.623597,43.5496209 163.273572,44.5192409 164.737393,45.9366966 C167.055798,48.1807873 168.215,50.8775637 168.215,54.028784 L168.215,54.028784 L168.215,141.112337 C168.215,144.263557 167.055798,146.961213 164.738301,149.205303 C162.420805,151.449394 159.635814,152.571 156.382423,152.571 L156.382423,152.571 L38.0484846,152.571 C34.7941856,152.571 32.0091955,151.449394 29.6907911,149.205303 C27.9631161,147.532352 26.8796772,145.607328 26.4400984,143.430232 L147.452455,143.429 C150.843314,143.429 153.745979,142.246216 156.161398,139.87972 C158.576817,137.513224 159.785,134.668424 159.785,131.345319 Z M141.950608,27.429 C145.205814,27.429 147.989897,28.5506059 150.307393,30.7946966 C152.625798,33.0387873 153.785,35.7355637 153.785,38.886784 L153.785,38.886784 L153.785,125.970337 C153.785,129.121557 152.625798,131.819213 150.308301,134.063303 C147.990805,136.307394 145.205814,137.429 141.952423,137.429 L141.952423,137.429 L23.6184846,137.429 C20.3641856,137.429 17.5791955,136.307394 15.2607911,134.063303 C12.9432944,131.819213 11.785,129.121557 11.785,125.970337 L11.785,125.970337 L11.785,38.886784 C11.785,35.7355637 12.9432944,33.0387873 15.2607911,30.7946966 C17.5791955,28.5506059 20.3641856,27.429 23.6184846,27.429 L23.6184846,27.429 Z",
    "id": "multi-image"
  }), 2), createVNode(32, "g", null, createVNode(32, "use", null, null, 1, {
    "id": "Shape",
    "href": "#multi-image"
  }), 2, {
    "stroke": "none",
    "stroke-width": "1",
    "fill-rule": "nonzero"
  })], 4, _objectSpread({
    "width": "180px",
    "height": "180px",
    "viewBox": "0 0 180 180",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg"
  }, props)));
}