'use strict';

import { createObjectPrototype } from 'component-registry';
import { IWorkflow } from 'influence-interfaces/workflow';
export var Workflow = createObjectPrototype({
  implements: [IWorkflow],
  constructor: function constructor(params) {
    if (!this.hasOwnProperty('_workflows')) {
      this._workflows = params._workflow || {};
      delete params._workflow;
    }
  }
});