import { MobiledocRenderer } from './RenderMobiledoc';
import { createElement } from 'inferno-create-element';
import { IMobiledocCardUtil, IMobiledocAtomUtil } from 'influence-interfaces/mobiledoc'; // 'app-field-MobiledocField'

export var utilityToCard = function utilityToCard(utility) {
  return {
    name: utility._name,
    type: utility.type,
    render: function render(props) {
      return createElement(utility.RenderComponent, props);
    }
  };
};
export var utilityToAtom = function utilityToAtom(utility) {
  return {
    name: utility._name,
    type: utility.type,
    component: utility.RenderComponent,
    render: function render(props) {
      return createElement(utility.RenderComponent, props);
    }
  };
};
export function getMobiledocRenderer() {
  var cardUtils = new IMobiledocCardUtil('*');
  var atomUtils = new IMobiledocAtomUtil('*');
  var config = {
    cards: cardUtils.map(function (util) {
      return utilityToCard(util);
    }),
    atoms: atomUtils.map(function (util) {
      return utilityToAtom(util);
    }),
    markups: [],
    sections: [],
    additionalProps: {}
  };
  return new MobiledocRenderer(config);
}