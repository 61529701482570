import { createObjectPrototype } from 'component-registry';
import assert from 'assert';
import { IPermissions } from 'influence-interfaces/permissions';
export var Permissions = createObjectPrototype({
  implements: [IPermissions],
  constructor: function constructor(params, permissions) {
    // We want to throw an error if no permissions are passed
    assert(typeof params._permissions === 'object' || typeof permissions === 'object', 'No permissions passed to Permissions constructor');

    if (permissions) {
      // Set permissions if they are specifically passed in the constructor
      if (!this._permissions) this._permissions = {};
      var keys = Object.keys(permissions);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        this._permissions[key] = permissions[key].map(function (perm) {
          return params._id ? perm.replace('{id}', params._id) : perm;
        });
      }
    } else {
      // Otherwise use what we get from params
      this._permissions = params._permissions;
    } // Make sure we have an owners array


    this._permissions.owners = params && params._permissions && params._permissions.owners || []; // Cleanup

    if (params && params.hasOwnProperty('_permissions')) {
      delete params._permissions;
    }
  }
});